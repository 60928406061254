import React, { useState, useEffect, Suspense } from 'react'
import Ripples from 'react-ripples';
import { Icon } from '@iconify/react';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../Loading';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useColorStore } from '../../../Store/ColorStore';
import { useUserStore } from '../../../Store/UserStore/UserStore';
import { useLeadActionStore } from './../../../Store/LeadStore/LeadAction';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { Months, numFormatter, numFormatter2, userSegrigation, userType, companyOptions, planOptions, productOptions, sumOptions, leadCategoryOptions, profileOptions, } from '../../../Export';
import { useUserPermissionStore } from '../../../Store/UserPermissionStore/UserPermissionStore';
import { leadFollowUpOptions } from '../../../Export';
import { useFieldLeadStore } from '../../../Store/LeadStore/FieldLeadStore';
import { useMisStore } from '../../../Store/MisStore/MisStore';
import { usePrStore } from './../../../Store/PrStore/PrStore';
import { Creatable } from 'react-select';
import './Mis.css'
import CreatableSelect from 'react-select/creatable';
import { CSVLink } from 'react-csv';
const MisList = React.lazy(() => { return import('./MisList') });
const animatedComponents = makeAnimated();


const Mis = () => {
  let token = JSON.parse(localStorage.getItem('user'))
  const location = useLocation()
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { color } = useColorStore()
  const { users, fetchUsers } = useUserStore()
  const [sortBy, setSortBy] = useState('NTO')
  const { fetchMis, mis, setMis, misOther, createFreshMis, importMis } = useMisStore()
  const { restoreLeads, trashLeads, assignLeads } = useLeadActionStore()
  const [reload, setReload] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const [searchInput, setSearchInput] = useState("")

  function search() {
    setReload(!reload)
  }

  useEffect(() => {
    if (users.length === 0) {
      fetchUsers()
    }
  }, [])

  // ------------------------------------------Permission Work---------------------------------------

  const [permission, setPermission] = useState(false)
  const [permission2, setPermission2] = useState(false)

  const { userPermissions } = useUserPermissionStore()
  useEffect(() => {
    let d = userPermissions.find((f) => {
      return f.permission_name === 'LEADS_PERMISSION'
    })
    let x = userSegrigation.find((f) => {
      return f.key === token.user_type
    })
    if (d && x) {
      setPermission(d[x.name])
    }
  }, [userPermissions])

  useEffect(() => {
    let d = userPermissions.find((f) => {
      return f.permission_name === 'OWNER_CHANGE_PERMISSION'
    })
    let x = userSegrigation.find((f) => {
      return f.key === token.user_type
    })
    if (d && x) {
      setPermission2(d[x.name])
    }
  }, [userPermissions])

  // ----------------------------------------------------------------------------Filteration work-------------------------------------------------------------------------------------------

  const [filterMode, setFilterMode] = useState(false)
  const [changeNotify, setChangeNotify] = useState(false)
  const [filterToggle, setFilterToggle] = useState(true)
  const [profileOption, setProfileOption] = useState([])
  const [owner, setOwner] = useState([])
  const [type, setType] = useState([])
  const [filterServiceState, setFilterServiceState] = useState([])
  const [status, setStatus] = useState([])
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  })
  // -------Options-----------
  const [options, setOptions] = useState([])

  useEffect(() => {
    setOptions([])
    let d = []
    for (let i = 0; i < users.length; i++) {
      const u = users[i];
      d.push({
        value: u.id, label: u.name
      })
    }
    setOptions(d)
  }, [users])

  const pendingOptions = [
    { value: "ISSUANCE", label: "ISSUANCE" },
    { value: "FORM UNFILLED", label: "FORM UNFILLED" },
    { value: "APPROVAL", label: "APPROVAL" },
    { value: "MEDICAL APPROVAL", label: "MEDICAL APPROVAL" },
    { value: "PAYMENT", label: "PAYMENT" },
    { value: "CHEQUE BOUNCE", label: "CHEQUE BOUNCE" }
  ]

  const statusOptions = [
    { value: "I", label: "Issued" },
    { value: "P", label: "Pending" },
    { value: "C", label: "Cancelled" },
    { value: "D", label: "Declined" },
    { value: "CC", label: "Cancelled by Client" },
    { value: "CB", label: "Cheque Bounce" },

  ]

  const typeOptions = [
    { value: "F", label: "Fresh" },
    { value: "R", label: "Renewal" },
    { value: "M", label: "Migration" },
    { value: "P", label: "Port" },
  ]

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: color.inputBackground,
      // match with the menu
      borderRadius: 4,
      // Overwrittes the different states of border
      borderColor: color.borderColor,
      fontSize: 14,
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: color.borderColor
      }
    }),
    menu: base => ({
      ...base,
      background: color.inputBackground,
      color: color.darkText,
      fontSize: 14,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isFocused ? color.blue : null,
        color: isFocused ? "#fff" : null,
        // color: "#333333"
      };
    }
  };

  const handleRemoveOwner = (index) => {
    setOwner([
      ...owner.slice(0, index),
      ...owner.slice(index + 1, owner.length)
    ]);
    setChangeNotify(true)
  }

  const handleRemoveFilter = (index) => {
    setFilterServiceState([
      ...filterServiceState.slice(0, index),
      ...filterServiceState.slice(index + 1, filterServiceState.length)
    ]);
    setChangeNotify(true)
  }

  const handleStatusChange = (index) => {
    setStatus([
      ...status.slice(0, index),
      ...status.slice(index + 1, status.length)
    ]);
    setChangeNotify(true)
  }

  const handleTypeChange = (index) => {
    setType([
      ...type.slice(0, index),
      ...type.slice(index + 1, type.length)
    ]);
    setChangeNotify(true)
  }

  const handleProfileOptionChange = (index) => {
    setProfileOption([
      ...profileOption.slice(0, index),
      ...profileOption.slice(index + 1, profileOption.length)
    ]);
    setChangeNotify(true)
  }

  useEffect(() => {
    if (location.search.length > 0 && options.length > 0) {
      const filterParams = new URLSearchParams(location.search);
      const nowner = filterParams.get('owner');
      const nfilterServiceState = filterParams.get('filterServiceState');
      const nstatus = filterParams.get('status');
      const nstartDate = filterParams.get('startDate');
      const nendDate = filterParams.get('endDate');
      const nsearch = filterParams.get('search');
      const ntype = filterParams.get('type');
      const nprofileOption = filterParams.get('profileOption');
      setStatus(statusOptions.filter((f) => {
        return nstatus.split(',').indexOf(String(f.value)) > -1;
      }))
      setOwner(options.filter((f) => {
        return nowner.split(',').indexOf(String(f.value)) > -1;
      }))
      setFilterServiceState(pendingOptions.filter((f) => {
        return nfilterServiceState.split(',').indexOf(String(f.value)) > -1;
      }))
      setType(typeOptions.filter((f) => {
        return ntype.split(',').indexOf(String(f.value)) > -1;
      }))
      setProfileOption(profileOptions.filter((f) => {
        return nprofileOption?.split(',').indexOf(String(f.value)) > -1;
      }))
      setDateRange({ ...dateRange, startDate: nstartDate, endDate: nendDate })
      setSearchInput(nsearch)
      setChangeNotify(true)
    }
    else {
      if (location.search.length === 0) {
        clearFilter()
      }
    }
  }, [location.search, options])

  const applyFilter = () => {
    setChangeNotify(false)
    if (owner.length > 0 || profileOption.length > 0 || filterServiceState.length > 0 || status.length > 0 || type.length > 0 || dateRange.startDate.length > 0 && dateRange.endDate.length > 0) {
      setFilterMode(true)
      setReload(!reload)
      let u = `?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&owner=${owner.map((f) => { return f.value })}&profileOption=${profileOption.map((f) => { return f.value })}&filterServiceState=${filterServiceState.map((f) => { return f.value })}&status=${status.map((f) => { return f.value })}&type=${type.map((f) => { return f.value })}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
      navigate(`${u}`, { replace: true });
    } else {
      clearFilter()
    }
  }

  const clearFilter = () => {
    setOwner([])
    setProfileOption([])
    setType([])
    setFilterServiceState([])
    setStatus([])
    setDateRange({ ...dateRange, startDate: '', endDate: '' })
    navigate('', { replace: true })
    setFilterMode(false)
    setReload(!reload)
  }

  useEffect(() => {
    if (changeNotify) {
      applyFilter()
    }
  }, [changeNotify])

  // ----------------Checked Ids Work---------------------------

  const [checked, setChecked] = useState([]);
  const handleCheckAllChange = (e) => {
    setChecked(
      e.target.checked ? mis.map((c) => c.id) : []
    );
  };

  const handleSingleChange = (e, c) => {
    setChecked((prevChecked) =>
      e.target.checked
        ? [...prevChecked, c.id]
        : prevChecked.filter((item) => item !== c.id)
    );
  };

  // --------------------Pagination Works------------------------------

  // Pagination Work Start

  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1)
  const [itemOffset, setItemOffset] = useState(0);
  const [itemEndSet, setItemEndSet] = useState(0)
  const [itemLength, setitemLength] = useState(0)

  useEffect(() => {
    if (misOther) {
      const endOffset = itemOffset + itemsPerPage;
      setPageCount(misOther.count / itemsPerPage);
      setItemEndSet(endOffset)
      setitemLength(misOther.count)
    }
  }, [misOther]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1)
    const newOffset = (event.selected * itemsPerPage) % misOther.count;
    setItemOffset(newOffset);
    setReload(!reload)
  };

  // ******************pagination work ends here***************************************


  // *************************************************Main Url Work****************************************************

  useEffect(() => {
    if (location.search.length > 0) {
      if (filterMode) {
        let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&profileOption=${profileOption.map((f) => { return f.value })}&owner=${owner.map((f) => { return f.value })}&filterServiceState=${filterServiceState.map((f) => { return f.value })}&status=${status.map((f) => { return f.value })}&type=${type.map((f) => { return f.value })}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
        setIsLoading(true)
        fetchMis(u, sortBy)
          .then(res => {
            setIsLoading(false)
            setMis(res)
          })
          .catch(err => {
            setIsLoading(false)
          })
      }
    } else {
      let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&profileOption=${profileOption.map((f) => { return f.value })}&owner=${owner.map((f) => { return f.value })}&filterServiceState=${filterServiceState.map((f) => { return f.value })}&status=${status.map((f) => { return f.value })}&type=${type.map((f) => { return f.value })}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
      setIsLoading(true)
      fetchMis(u, sortBy)
        .then(res => {
          setIsLoading(false)
          setMis(res)
        })
        .catch(err => {
          setIsLoading(false)
        })
    }
  }, [reload])

  // ******************************************************************************************************
  const { pr, fetchPr, setPr } = usePrStore()
  const fetch = () => {
    setIsLoading(true)
    fetchPr()
      .then(res => {
        setIsLoading(false)
        setPr(res)
      })
      .catch(err => {
        setIsLoading(false)
        console.log(err);
      })
  }
  useEffect(() => {
    fetch()
  }, [])

  // -------------------------------------------------------------------------------------------------------


  const [payload, setPayload] = useState({
    lead_c: 'HEALTH', enquiry: '', company: '', profile_option: '', type: '', month: '', policy_number: '', policy_status: 'P', source_by: '', picked_by: '', proposed_by: '', sourcing_date: '', login_date: null, insured_person: '', contact: '', alt_contact: '', mail: '', product: '', medical: '', sum_assured: '', plan: '', prev_policy_number: '', gross_premium: '', gst: '', net_premium: '', payment_mode: '', bank_name: '', cheque_number: '', cheque_date: null, discount: '', quote: '', address: '',
  })

  useEffect(() => {
    if (payload.gross_premium.length > 0) {
      let amount_recieved = parseFloat(parseFloat(payload.gross_premium) * 100 / 118).toFixed(2)
      setPayload({ ...payload, net_premium: String(amount_recieved), gst: String(parseFloat(payload.gross_premium) - amount_recieved) })
    }
  }, [payload.gross_premium])

  const handleCreateMis = () => {
    if (payload.lead_c.length > 0 && payload.company.length > 0 && payload.type.length > 0 && payload.month.length > 0 && payload.sourcing_date.length > 0 && payload.insured_person.length > 0 && payload.contact.length > 0 && payload.plan.length > 0 && payload.product.length > 0 && payload.gross_premium.length > 0 && payload.gst.length > 0 && payload.net_premium.length > 0 && payload.sum_assured.length > 0 && payload.payment_mode.length > 0 && payload.source_by.length > 0 && payload.picked_by.length > 0 && payload.proposed_by.length > 0) {
      setIsLoading(true)
      console.log(payload);

      createFreshMis(payload)
        .then(res => {
          document.getElementById("dltBtn").click()
          setIsLoading(false)
          setReload(!reload)
          addToast('Mis generated...', { appearance: 'success' });
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false)
        })
    } else {
      addToast('Please enter required fields...', { appearance: 'error' });
    }
  }

  const [csvFile, setCsvFile] = useState()

  const handleImportMis = () => {
    if (csvFile) {
      setIsLoading(true)
      let formData = new FormData()
      formData.append('file', csvFile)
      importMis(formData)
        .then(res => {
          document.getElementById('dltBtn2').click()
          setIsLoading(false)
          setCsvFile(null)
          setReload(!reload)
          addToast('Success...', { appearance: 'success' });
        })
        .catch(err => {
          console.log(err.response.data);
          setIsLoading(false)
          if (err.response && err.response.status === 400) {
            if (typeof (err.response.data) === String) {
              addToast(err.response.data, { appearance: 'error' });
            } else {
              addToast('Something went wrong...', { appearance: 'error' });
            }
          } else {
            addToast('Something went wrong...', { appearance: 'error' });
          }
        })
    } else {
      addToast('Please attach file...', { appearance: 'error' });
    }
  }

  let alist = [{
    mis_category: '',
    company: '',
    code: '',
    type: '',
    month: '',
    picked_by: '',
    source_by: '',
    sourcing_date: '',
    login_date: '',
    policy_number: '',
    policy_status: '',
    proposed_by: '',
    insured_person: '',
    address: '',
    contact: '',
    alt_contact: '',
    mail: '',
    product: '',
    medical: '',
    sum_assured: '',
    plan: '',
    prev_policy_number: '',
    gross_premium: '',
    gst: '',
    net_premium: '',
    payment_mode: '',
    bank_name: '',
    cheque_number: '',
    cheque_date: '',
  }]
  return (
    <>

      <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
        <div className="myrow justify_space_between mb-4">
          <div className="ico-search d-flex align_center" style={{ position: 'relative' }}>
            <div>
              <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
              <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
            </div>
            <div className='ms-2'>
              <Ripples color='rgba(255,255,255,0.2)' className='dis_block mb-0' during={650}>
                <button onClick={search} className="primaryButton "><Icon icon="ion:search-outline" fontSize={18} /></button>
              </Ripples>
            </div>
          </div>
          <div className='myrow'>
            <Ripples color='rgba(255,255,255,0.2)' className='dis_block me-2' during={800}>
              <button className='successButton' data-bs-toggle="modal" data-bs-target={"#importModal"}>Import</button>
            </Ripples>
            <Ripples color='rgba(255,255,255,0.2)' className='dis_block me-2' during={800}>
              <button className='primaryButton' data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal"}>Create</button>
            </Ripples>
            <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={800}>
              <button data-toggle="tooltip" data-placement="top" title="Filter" className="infoButton" data-bs-toggle="offcanvas" data-bs-target="#MisFilterCanvas" aria-controls="LeadsFilterCanvas" ><Icon icon="mdi:filter" fontSize={15} /> Filter</button>
            </Ripples>
          </div>
        </div>

        {filterMode &&
          <div className='filter_values_wrapper'>
            <div className="filter_values_title" style={{ color: color.darkText }}>Filter by &nbsp;:<span onClick={clearFilter} className="clear_filter_link">Clear filter</span></div>
            {status.length > 0 ?
              status.map((u, count) => {
                return <div className="filter_values" key={count}>{u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleStatusChange(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
              })
              : null
            }
            {type.length > 0 ?
              type.map((u, count) => {
                return <div className="filter_values" key={count}>{u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleTypeChange(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
              })
              : null
            }
            {owner.length > 0 ?
              owner.map((u, count) => {
                return <div className="filter_values" key={count}>{u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveOwner(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
              })
              : null
            }
            {profileOption.length > 0 ?
              profileOption.map((u, count) => {
                return <div className="filter_values" key={count}>{u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleProfileOptionChange(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
              })
              : null
            }
            {filterServiceState.length > 0 ?
              filterServiceState.map((u, count) => {
                return <div className="filter_values" key={count}>{u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveFilter(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
              })
              : null
            }

            {dateRange.startDate.length > 0 && dateRange.endDate.length > 0 &&
              <div className="filter_values">
                {moment(dateRange.startDate).format('ll')} - {moment(dateRange.endDate).format('ll')} <Icon icon="fa6-solid:x" onClick={() => { setDateRange({ ...dateRange, startDate: '', endDate: '' }); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
              </div>
            }

          </div>
        }

        <div className="table-responsive mt-3">
          <div className="myrow" style={{justifyContent:'space-between'}}>
            <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
              Show Entries - <select value={itemsPerPage} onChange={(e) => { setItemsPerPage(Number(e.target.value)); setReload(!reload) }} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '75px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                <option value={10}>10</option>
                <option value={1}>1</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              &nbsp; Out of - {misOther ? misOther.count : 0}
            </div>

            <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
              Sort By : <select value={sortBy} onChange={(e) => { setSortBy(e.target.value); setReload(!reload) }} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '150px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                <option value={'NTO'}>New to Old</option>
                <option value={'OTN'}>Old to New</option>
                <option value={'PLTH'}>Premium - Low to High</option>
                <option value={'PHTL'}>Premium - High to Low</option>
              </select>
              
            </div>
          </div>
          <div className="myrow">
            <div style={{ color: color.darkText, fontSize: 20, marginBottom: '7px', paddingRight: '8px', borderRight: '1px solid ' + color.borderBottomColor }}>
              Sourcing Amount - <span style={{ color: color.yellow }}>{misOther ? '₹ ' + numFormatter(misOther.ts) : 0}</span>
            </div>
            <div style={{ color: color.darkText, fontSize: 20, marginBottom: '7px', paddingLeft: '8px', }}>
              Issued Amount - <span style={{ color: color.green }}>{misOther ? '₹ ' + numFormatter(misOther.s) : 0}</span>
            </div>
          </div>
          {checked.length > 0 &&
            <div style={{ color: color.blue2, fontSize: "13px", marginBottom: '10px' }}>
              {checked.length} Entries are Selected. <u onClick={() => setChecked([])} style={{ color: color.red, cursor: 'pointer' }}> Clear all </u>
            </div>
          }
          <table className="table table-nowrap align-middle mb-3 mt-3 table-bordered table-striped">
            <thead style={{ backgroundColor: color.tableHeader }}>
              <tr style={{ color: color.darkText }}>
                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                  S.No.
                </th>
                <th style={{ color: color.darkText }} scope="col">Category</th>
                <th style={{ color: color.darkText }} scope="col">Company</th>
                <th style={{ color: color.darkText }} scope="col">Type</th>
                <th style={{ color: color.darkText }} scope="col">Profile</th>
                <th style={{ color: color.darkText }} scope="col">Proposer</th>
                <th style={{ color: color.darkText }} scope="col">Insured Person</th>
                <th style={{ color: color.darkText }} scope="col">Policy Number</th>
                <th style={{ color: color.darkText }} scope="col">Policy Duration</th>
                <th style={{ color: color.darkText }} scope="col">Policy Status</th>
                <th style={{ color: color.darkText }} scope="col">Source By</th>
                <th style={{ color: color.darkText }} scope="col">Pick up By</th>
                <th style={{ color: color.darkText }} scope="col">Sourcing Date</th>
                <th style={{ color: color.darkText }} scope="col">Login Date</th>
                {token.user_type === userType.admin || token.user_type === userType.operation_manager ?
                  <th style={{ color: color.darkText }} scope="col">Alternate Contact</th>
                  : null}
                {token.user_type === userType.admin || token.user_type === userType.operation_manager ? <th style={{ color: color.darkText, minWidth: '150px' }} scope="col">Address</th> : null}
                <th style={{ color: color.darkText }} scope="col">Product</th>
                {token.user_type === userType.admin || token.user_type === userType.operation_manager ? <th style={{ color: color.darkText }} scope="col">Medical/Non-Medical</th> : null}
                {token.user_type === userType.admin || token.user_type === userType.operation_manager ? <th style={{ color: color.darkText }} scope="col">Sum Assured</th> : null}
                {token.user_type === userType.admin || token.user_type === userType.operation_manager ? <th style={{ color: color.darkText }} scope="col">Plan</th> : null}
                <th style={{ color: color.darkText }} scope="col">Previous Policy Number</th>
                {token.user_type === userType.admin || token.user_type === userType.operation_manager ? <th style={{ color: color.darkText }} scope="col">Gross Premium</th> : null}
                {token.user_type === userType.admin || token.user_type === userType.operation_manager ? <th style={{ color: color.darkText }} scope="col">GST</th> : null}
                <th style={{ color: color.darkText }} scope="col">Net Premium</th>
                {token.user_type === userType.admin || token.user_type === userType.operation_manager ? <th style={{ color: color.darkText }} scope="col">Payment Mode</th> : null}
                {token.user_type === userType.admin || token.user_type === userType.operation_manager ? <th style={{ color: color.darkText }} scope="col">Bank Name</th> : null}
                {token.user_type === userType.admin || token.user_type === userType.operation_manager ? <th style={{ color: color.darkText }} scope="col">Cheque Number & Date</th> : null}
                {token.user_type === userType.admin || token.user_type === userType.operation_manager ? <th style={{ color: color.darkText }} scope="col">Discount</th> : null}
                {token.user_type === userType.admin || token.user_type === userType.operation_manager ? <th style={{ color: color.darkText }} scope="col">Qoute No</th> : null}
                <th style={{ color: color.darkText }} scope="col">Policy File</th>

                {token.user_type === userType.admin && <th style={{ color: color.darkText }} scope="col">Delete</th>}
              </tr>
            </thead>

            <tbody>
              {mis.length > 0 ?
                mis.map((c, count) => {
                  return <Suspense key={c.id} fallback={<></>}>
                    <MisList checked={checked} pr={pr} users={users} handleSingleChange={handleSingleChange} reload={reload} setReload={setReload} data={c} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                  </Suspense>
                })
                :
                <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>
              }
            </tbody>
          </table>
          {mis.length > 0 &&
            <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
          }

          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="pagination-active"
            renderOnZeroPageCount={null}
          />

        </div>

      </div>

      {/* ----------------------------Create MIS Modal------------------------ */}

      <div class="modal fade" id={"categoryDeleteModal"} tabindex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

            <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
              <div class="modal_title" style={{ color: color.darkText }} id="followUpModalLabel">Create MIS</div>
              <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
            </div>

            <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

              <div className="myrow justify_space_between">

                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Mis Category <span className="text-danger">*</span></label>
                    <select value={payload.lead_c} onChange={(e) => setPayload({ ...payload, lead_c: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                      {leadCategoryOptions.map((m, c) => {
                        return <option key={c} value={m.value}>{m.label}</option>
                      })}
                    </select>
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Company <span className="text-danger">*</span></label>
                    <CreatableSelect options={companyOptions} styles={customStyles} value={{ value: payload.company, label: payload.company }} onChange={(e) => setPayload({ ...payload, company: e.value })} />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Type <span className="text-danger">*</span></label>
                    <select value={payload.type} onChange={(e) => setPayload({ ...payload, type: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                      <option value="">----select type----</option>
                      <option value="F">Fresh</option>
                      <option value="M">Migration</option>
                      <option value="R">Renewal</option>
                      <option value="P">Port</option>
                    </select>
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Month <span className="text-danger">*</span></label>
                    <select value={payload.month} onChange={(e) => setPayload({ ...payload, month: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                      <option value="">----select month----</option>
                      {Months.map((d, c) => {
                        return <option key={c} value={d.key}>{d.value}</option>
                      })}
                    </select>
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Source By <span className="text-danger">*</span></label>
                    <select value={payload.picked_by} onChange={(e) => setPayload({ ...payload, picked_by: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                      <option value="">----select source by----</option>
                      {users.map((d) => {
                        return <option value={d.id} key={d.id}>{d.name}</option>
                      })}
                    </select>
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Pick Up By <span className="text-danger">*</span></label>
                    <select value={payload.source_by} onChange={(e) => setPayload({ ...payload, source_by: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                      <option value="">----select pickup by----</option>
                      {users.map((d) => {
                        return <option value={d.id} key={d.id}>{d.name}</option>
                      })}
                    </select>
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Proposer <span className="text-danger">*</span></label>
                    <input type='text' value={payload.proposed_by} onChange={(e) => setPayload({ ...payload, proposed_by: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Policy number</label>
                    <input type='text' value={payload.policy_number} onChange={(e) => setPayload({ ...payload, policy_number: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Policy Status</label>
                    <select value={payload.policy_status} onChange={(e) => setPayload({ ...payload, policy_status: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                      <option value="P">Pending</option>
                      <option value="I">Issued</option>
                      <option value="C">Cancelled</option>
                      <option value="D">Declined</option>
                      <option value="CC">Cancelled by Client</option>
                      <option value="CB">Cheque Bounce</option>
                    </select>
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Sourcing Date <span className="text-danger">*</span></label>
                    <input type='date' value={payload.sourcing_date} onChange={(e) => setPayload({ ...payload, sourcing_date: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Login Date</label>
                    <input type='date' value={payload.login_date} onChange={(e) => setPayload({ ...payload, login_date: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Insured Person <span className="text-danger">*</span></label>
                    <input type='text' value={payload.insured_person} onChange={(e) => setPayload({ ...payload, insured_person: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Contact <span className="text-danger">*</span></label>
                    <input type='text' value={payload.contact} onChange={(e) => setPayload({ ...payload, contact: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Alternate Contact</label>
                    <input type='text' value={payload.alt_contact} onChange={(e) => setPayload({ ...payload, alt_contact: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Mail ID</label>
                    <input type='text' value={payload.mail} onChange={(e) => setPayload({ ...payload, mail: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Product <span className="text-danger">*</span></label>
                    <CreatableSelect options={productOptions} styles={customStyles} value={{ value: payload.product, label: payload.product }} onChange={(e) => setPayload({ ...payload, product: e.value })} />
                    {/* <input type='text' value={payload.product} onChange={(e) => setPayload({ ...payload, product: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' /> */}
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Medical/Non-Medical</label>
                    <select value={payload.medical} onChange={(e) => setPayload({ ...payload, medical: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                      <option value="">----select medical----</option>
                      <option value="Medical">Medical</option>
                      <option value="Non-Medical">Non-Medical</option>
                    </select>
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Sum Assured <span className="text-danger">*</span></label>
                    <CreatableSelect options={sumOptions} styles={customStyles} value={{ value: payload.sum_assured, label: payload.sum_assured }} onChange={(e) => setPayload({ ...payload, sum_assured: e.value })} />
                    {/* <input type='text' value={payload.sum_assured} onChange={(e) => setPayload({ ...payload, sum_assured: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' /> */}
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Plan <span className="text-danger">*</span></label>
                    <CreatableSelect options={planOptions} styles={customStyles} value={{ value: payload.plan, label: payload.plan }} onChange={(e) => setPayload({ ...payload, plan: e.value })} />
                    {/* <input type='text' value={payload.plan} onChange={(e) => setPayload({ ...payload, plan: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' /> */}
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Previous Policy</label>
                    <input type='text' value={payload.prev_policy_number} onChange={(e) => setPayload({ ...payload, prev_policy_number: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Gross Premium <span className="text-danger">*</span></label>
                    <input type='text' value={payload.gross_premium} onChange={(e) => setPayload({ ...payload, gross_premium: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>GST <small>(18% Inc.)</small> <span className="text-danger">*</span></label>
                    <input type='text' value={payload.gst} onChange={(e) => setPayload({ ...payload, gst: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Net Premium <span className="text-danger">*</span></label>
                    <input type='text' value={payload.net_premium} onChange={(e) => setPayload({ ...payload, net_premium: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Payment Mode <span className="text-danger">*</span></label>
                    <select value={payload.payment_mode} onChange={(e) => setPayload({ ...payload, payment_mode: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                      <option value="">--------Select mode--------</option>
                      <option value="ONLINE">ONLINE</option>
                      <option value="CHEQUE">CHEQUE</option>
                    </select>
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Bank Name</label>
                    <input type='text' value={payload.bank_name} onChange={(e) => setPayload({ ...payload, bank_name: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Cheque Number</label>
                    <input type='text' value={payload.cheque_number} onChange={(e) => setPayload({ ...payload, cheque_number: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Cheque Date</label>
                    <input type='date' value={payload.cheque_date} onChange={(e) => setPayload({ ...payload, cheque_date: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Discount</label>
                    <input type='text' value={payload.discount} onChange={(e) => setPayload({ ...payload, discount: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Quote</label>
                    <input type='text' value={payload.quote} onChange={(e) => setPayload({ ...payload, quote: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Start Date <span className="text-danger">*</span></label>
                    <input type='date' value={payload.start_date} onChange={(e) => setPayload({ ...payload, start_date: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>End Date <span className="text-danger">*</span></label>
                    <input type='date' value={payload.end_date} onChange={(e) => setPayload({ ...payload, end_date: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                  </div>
                </div>
                <div style={{ width: '32%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Profile Option</label>
                    <CreatableSelect placeholder={'Select...'} options={profileOptions} styles={customStyles} value={{ value: payload.profile_option, label: payload.profile_option }} onChange={(e) => setPayload({ ...payload, profile_option: e.value })} />
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <div className='mb-2'>
                    <label className='modal_label' style={{ color: color.darkText }}>Address</label>
                    <textarea value={payload.address} onChange={(e) => setPayload({ ...payload, address: e.target.value })} placeholder='Enter address...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' ></textarea>
                  </div>
                </div>
              </div>

            </div>

            <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
              <button type="button" id={"dltBtn"} style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
              <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleCreateMis}>{isLoading ?
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                  Please wait...
                </>
                : "Create"}</button>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------------------Import  Modal------------------------ */}

      <div class="modal fade" id={"importModal"} tabindex="-1" aria-labelledby="importModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
            <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
              <div class="modal_title" style={{ color: color.darkText }} id="importModalLabel">Create MIS</div>
              <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
            </div>

            <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
              <div className='mb-2'>
                <label className='modal_label' style={{ color: color.darkText }}>Attach File (.csv,.xlsx) <span className="text-danger">*</span></label>
                <input type='file' value={csvFile ? csvFile.filename : ''} onChange={(e) => setCsvFile(e.target.files[0])} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>
              {<CSVLink data={alist} filename={"sample.csv"}><div className='text-start'> <span style={{ fontSize: '14px', color: color.blue2 }}>Click here to Download CSV Format... </span></div></CSVLink>}
            </div>

            <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
              <button type="button" id={"dltBtn2"} style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
              <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleImportMis}>{isLoading ?
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                  Please wait...
                </>
                : "Create"}</button>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------Filter modal----------------------------------- */}

      <div className="offcanvas offcanvas-end" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} tabIndex="-1" id="MisFilterCanvas" aria-labelledby="offcanvasRightLabel">
        <div className="modal-content">
          <div className="modal-header px-3 pt-3" style={{ borderBottomColor: color.tableBorder }}>
            <div className="modal_title" style={{ color: color.darkText }} id="offcanvasRightLabel">Filter</div>
            <span type="button" id='canvaCloseId' className='modal_close' data-bs-dismiss="offcanvas" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
          </div>
        </div>
        <hr style={{ borderTop: "0.5px solid " + color.tableBorder, opacity: '1' }} />
        <div className="offcanvas-body">
          <div className="mb-3">
            <label className='modal_label' style={{ color: color.darkText }}>Profile Option</label>
            <Select
              styles={customStyles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={profileOptions}
              onChange={(e) => setProfileOption(e)}
              value={profileOption}
            />
          </div>
          <div className="mb-3">
            <label className='modal_label' style={{ color: color.darkText }}>Status</label>
            <Select
              styles={customStyles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={statusOptions}
              onChange={(e) => setStatus(e)}
              value={status}
            />
          </div>

          <div className="mb-3">
            <label className='modal_label' style={{ color: color.darkText }}>Type</label>
            <Select
              styles={customStyles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={typeOptions}
              onChange={(e) => setType(e)}
              value={type}
            />
          </div>

          <div className="mb-3">
            <label className='modal_label' style={{ color: color.darkText }}>Pending Status</label>
            <Select
              styles={customStyles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={pendingOptions}
              onChange={(e) => setFilterServiceState(e)}
              value={filterServiceState}
            />
          </div>

          <div className="mb-3">
            <label className='modal_label' style={{ color: color.darkText }}>Source By</label>
            <Select
              styles={customStyles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={options}
              onChange={(e) => setOwner(e)}
              value={owner}
            />
          </div>

          <label className='modal_label' style={{ color: color.lightText }}>Source Date Range</label>
          <div className="mb-4 mt-1">
            <label className='modal_label' style={{ color: color.darkText }}>Start Date</label>
            <input type="date" value={dateRange.startDate} onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className="mb-4">
            <label className='modal_label' style={{ color: color.darkText }}>End Date</label>
            <input type="date" value={dateRange.endDate} onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className="pt-4">

            <button className="primaryButton me-3" id='filterBtn' onClick={applyFilter}>Apply filter</button>
            <button className="secondaryButton" onClick={clearFilter}>Clear filter</button>

          </div>
        </div>
      </div>

      {isLoading && <Loading />}
    </>
  )
}

export default Mis