import React, { useState, useEffect, Suspense } from 'react'
import Ripples from 'react-ripples';
import { Icon } from '@iconify/react';
import { useToasts } from 'react-toast-notifications';
import ReactPaginate from 'react-paginate';
import Select, { createFilter, useStateManager } from 'react-select';
import makeAnimated from 'react-select/animated';
import { useColorStore } from '../../../Store/ColorStore';
import { CSVLink } from "react-csv";
import moment from 'moment';
import Loading from './../../../Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserStore } from './../../../Store/UserStore/UserStore';
import { useClaimStore } from './../../../Store/ClaimStore/ClaimStore';
import { userType } from '../../../Export';
const ClaimList = React.lazy(() => { return import('./ClaimList') });
const animatedComponents = makeAnimated();

const Claims = () => {
    let token = JSON.parse(localStorage.getItem('user'))
    let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
    const location = useLocation()
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const { color } = useColorStore()
    const { users, fetchUsers } = useUserStore()
    const { claims, fetchClaims, setClaims, claimsOther, createClaim, clearTaskData } = useClaimStore()
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [searchInput, setSearchInput] = useState("")

    function search() {
        setReload(!reload)
    }

    useEffect(() => {
        if (users.length === 0) {
            fetchUsers()
        }
    }, [])


    // ----------------------------------------------------------------------------Filteration work-------------------------------------------------------------------------------------------

    const [filterMode, setFilterMode] = useState(false)
    const [changeNotify, setChangeNotify] = useState(false)
    const [filterToggle, setFilterToggle] = useState(true)
    const [owner, setOwner] = useState([])
    const [filterServiceState, setFilterServiceState] = useState([])
    const [status, setStatus] = useState([])
    const [pay, setPay] = useState('')
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: '',
    })
    // -------Options-----------
    const [options, setOptions] = useState([])

    const leadServiceStateOptions = [
        { value: 'P', label: 'Paid' },
        { value: 'R', label: 'Renew' },
    ]

    useEffect(() => {
        setOptions([])
        let d = []
        for (let i = 0; i < users.length; i++) {
            const u = users[i];
            d.push({
                value: u.id, label: u.name
            })
        }
        setOptions(d)
    }, [users])

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: color.inputBackground,
            // match with the menu
            borderRadius: 4,
            // Overwrittes the different states of border
            borderColor: color.borderColor,
            fontSize: 14,
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: color.borderColor
            }
        }),
        menu: base => ({
            ...base,
            background: color.inputBackground,
            color: color.darkText,
            fontSize: 14,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);

            return {
                ...styles,
                backgroundColor: isFocused ? color.blue : null,
                color: isFocused ? "#fff" : null,
                // color: "#333333"
            };
        }
    };

    const handleRemoveOwner = (index) => {
        setOwner([
            ...owner.slice(0, index),
            ...owner.slice(index + 1, owner.length)
        ]);
    }
    const handleRemoveFilterServiceState = (index) => {
        setFilterServiceState([
            ...filterServiceState.slice(0, index),
            ...filterServiceState.slice(index + 1, filterServiceState.length)
        ]);
    }
    const handleRemoveFilterStatus = (index) => {
        setStatus('');
    }

    useEffect(() => {
        if (location.search.length > 0 && options.length > 0) {
            const filterParams = new URLSearchParams(location.search);
            const nstatus = filterParams.get('status');
            const npay = filterParams.get('pay');
            const nowner = filterParams.get('owner');
            const nstartDate = filterParams.get('startDate');
            const nendDate = filterParams.get('endDate');
            const nsearch = filterParams.get('search');
            setStatus(nstatus)
            setPay(npay)
            setOwner(options.filter((f) => {
                return nowner.split(',').indexOf(String(f.value)) > -1;
            }))
            setDateRange({ ...dateRange, startDate: nstartDate, endDate: nendDate })
            setSearchInput(nsearch)
            setChangeNotify(true)
        }
        else {
            if (location.search.length === 0) {
                clearFilter()
            }
        }
    }, [location.search, options])

    const applyFilter = () => {
        setChangeNotify(false)
        if (owner.length > 0 || pay.length > 0 || filterServiceState.length > 0 || status.length > 0 || dateRange.startDate.length > 0 && dateRange.endDate.length > 0) {
            setFilterMode(true)
            setFilterToggle(!filterToggle)
            let u = `?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&status=${status}&pay=${pay}&owner=${owner.map((f) => { return f.value })}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
            navigate(`${u}`);
        } else {
            clearFilter()
        }
    }

    const clearFilter = () => {
        setOwner([])
        setFilterServiceState([])
        setStatus([])
        setPay('')
        setDateRange({ ...dateRange, startDate: '', endDate: '' })
        navigate('')
        setFilterMode(false)
        setReload(!reload)
    }

    useEffect(() => {
        if (changeNotify) {
            applyFilter()
        }
    }, [changeNotify])

    // ----------------Checked Ids Work---------------------------

    const [checked, setChecked] = useState([]);
    const handleCheckAllChange = (e) => {
        setChecked(
            e.target.checked ? claims.map((c) => c.id) : []
        );
    };

    const handleSingleChange = (e, c) => {
        setChecked((prevChecked) =>
            e.target.checked
                ? [...prevChecked, c.id]
                : prevChecked.filter((item) => item !== c.id)
        );
    };

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (claimsOther) {
            const endOffset = itemOffset + itemsPerPage;
            setPageCount(claimsOther.count / itemsPerPage);
            setItemEndSet(endOffset)
            setitemLength(claimsOther.count)
        }
    }, [claimsOther]);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
        const newOffset = (event.selected * itemsPerPage) % claimsOther.count;
        setItemOffset(newOffset);
    };

    // ******************pagination work ends here***************************************

    // *************************************************Main Url Work****************************************************

    useEffect(() => {
        let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&pay=${pay}&owner=${owner.map((f) => { return f.value })}&status=${status}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
        setIsLoading(true)
        fetchClaims(u)
            .then(res => {
                setIsLoading(false)
                setClaims(res)
            })
            .catch(err => {
                setIsLoading(false)
            })
    }, [reload, itemsPerPage, currentPage, filterToggle])

    // *******************************

    const [payload, setPayload] = useState({
        proposer_name: '', patient_name: '', policy_number: '', claim_number: '', hospital: '', hospitalisation_date: null, dispatch_date: null, pod: '', remark: '', status: 'P', created_by: String(token.id)
    })

    const handleSubmit = () => {
        if (payload.proposer_name.length > 0 && payload.patient_name.length > 0 && payload.policy_number.length > 0 && payload.created_by.length > 0) {
            setIsLoading(true)
            createClaim(payload)
                .then(res => {
                    setIsLoading(false)
                    setReload(!reload)
                    setPayload({ ...payload, name: '', desc: '', assign_to: '', owner: String(token.id), f: [] })
                    addToast('Success...', { appearance: 'success' });

                })
                .catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        } else {
            addToast('All fields required...', { appearance: 'error' });
        }
    }

    return (
        <>

            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search d-flex align_center" style={{ position: 'relative' }}>
                        <div>
                            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
                            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                        </div>
                        <div className='ms-2'>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block mb-0' during={650}>
                                <button onClick={search} className="primaryButton "><Icon icon="ion:search-outline" fontSize={18} /></button>
                            </Ripples>
                        </div>
                    </div>
                    <div className="myrow">
                        <Ripples color='rgba(255,255,255,0.2)' className='dis_block me-2' during={800}>
                            <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#createModal" ><Icon icon="mdi:plus" fontSize={15} /> Create Claim</button>
                        </Ripples>
                        <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={800}>
                            <button data-toggle="tooltip" data-placement="top" title="Filter" className="infoButton" data-bs-toggle="offcanvas" data-bs-target="#LeadsFilterCanvas" aria-controls="LeadsFilterCanvas" ><Icon icon="mdi:filter" fontSize={15} /> Filter</button>
                        </Ripples>
                    </div>
                </div>

                {filterMode &&
                    <div className='filter_values_wrapper'>
                        <div className="filter_values_title" style={{ color: color.darkText }}>Filter by &nbsp;:<span onClick={clearFilter} className="clear_filter_link">Clear filter</span></div>

                        {status.length > 0 ?
                            <div className="filter_values">{status === 'P' ? 'Pending' : status === 'C' ? 'Completed' : ''} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveFilterStatus(); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            : null
                        }
                        {pay.length > 0 ?
                            <div className="filter_values">{pay} <Icon icon="fa6-solid:x" onClick={() => { setPay(''); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            : null
                        }

                        {owner.length > 0 ?
                            owner.map((u, count) => {
                                return <div className="filter_values" key={count}>{'Created By : ' + u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveOwner(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            })
                            : null
                        }

                        {dateRange.startDate.length > 0 && dateRange.endDate.length > 0 &&
                            <div className="filter_values">
                                {moment(dateRange.startDate).format('ll')} - {moment(dateRange.endDate).format('ll')} <Icon icon="fa6-solid:x" onClick={() => { setDateRange({ ...dateRange, startDate: '', endDate: '' }); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
                            </div>
                        }

                    </div>
                }

                <div className="table-responsive mt-3">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '75px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                            <option value={10}>10</option>
                            <option value={1}>1</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        &nbsp; Out of - {claimsOther ? claimsOther.count : 0}
                    </div>

                    <table className="table table-nowrap align-middle my-2 table-bordered table-striped">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Proposer Name</th>
                                <th style={{ color: color.darkText }} scope="col">Patient Name</th>
                                <th style={{ color: color.darkText }} scope="col">Policy Number</th>
                                <th style={{ color: color.darkText }} scope="col">Claim Number</th>
                                <th style={{ color: color.darkText }} scope="col">Hospital</th>
                                {/* <th style={{ color: color.darkText }} scope="col">Hospitalisation Date</th> */}
                                <th style={{ color: color.darkText }} scope="col">Dispatch Date</th>
                                <th style={{ color: color.darkText }} scope="col">POD Details</th>
                                <th style={{ color: color.darkText }} scope="col">Created By</th>
                                <th style={{ color: color.darkText }} scope="col">Status</th>
                                <th style={{ color: color.darkText }} scope="col">Pay</th>
                                <th style={{ color: color.darkText }} scope="col">Remark</th>
                                <th scope="col" style={{ color: color.darkText }}>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {claims.length > 0 ?
                                claims.map((c, count) => {
                                    return <Suspense key={c.id} fallback={<></>}>
                                        <ClaimList reload={reload} setReload={setReload} users={users} checked={checked} data={c} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                    </Suspense>
                                })
                                :
                                <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>
                            }
                        </tbody>
                    </table>
                    {claims.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />

                </div>

            </div>

            {/* ------------------------------Add modal----------------------------------- */}

            <div class="modal fade" id="createModal" tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Add Task</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            <div className='mb-1'>
                                <label className='modal_label' style={{ color: color.darkText }}>Proposer Name <span className="text-danger">*</span></label>
                                <input placeholder='Enter Proposer name' value={payload.proposer_name} onChange={(e) => setPayload({ ...payload, proposer_name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                            <div className='mb-1'>
                                <label className='modal_label' style={{ color: color.darkText }}>Patient Name <span className="text-danger">*</span></label>
                                <input placeholder='Enter Patient name' value={payload.patient_name} onChange={(e) => setPayload({ ...payload, patient_name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                            <div className='mb-1'>
                                <label className='modal_label' style={{ color: color.darkText }}>Policy Number <span className="text-danger">*</span></label>
                                <input placeholder='Enter Policy Number' value={payload.policy_number} onChange={(e) => setPayload({ ...payload, policy_number: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                            {token.user_type === userType.admin || token.user_type === userType.service_support && supervisor ? <>
                                <div className='mb-1'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Claim Number</label>
                                    <input placeholder='Enter Claim Number' value={payload.claim_number} onChange={(e) => setPayload({ ...payload, claim_number: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>
                                <div className='mb-1'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Hospital</label>
                                    <input placeholder='Enter Hospital' value={payload.hospital} onChange={(e) => setPayload({ ...payload, hospital: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>
                                <div className='mb-1'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Hospitalisation Date</label>
                                    <input type='date' value={payload.hospitalisation_date} onChange={(e) => setPayload({ ...payload, hospitalisation_date: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>
                                <div className='mb-1'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Dispatch Date</label>
                                    <input type='date' value={payload.dispatch_date} onChange={(e) => setPayload({ ...payload, dispatch_date: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>
                                <div className='mb-1'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Pod Details</label>
                                    <input placeholder='Enter Pod Details' value={payload.pod} onChange={(e) => setPayload({ ...payload, pod: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>
                                <div className='mb-1'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Remark</label>
                                    <textarea placeholder='Enter Remark' value={payload.remark} onChange={(e) => setPayload({ ...payload, remark: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'></textarea>
                                </div>
                                <div className='mb-1'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Status</label>
                                    <select value={payload.status} onChange={(e) => setPayload({ ...payload, status: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                        <option value="P">Pending</option>
                                        <option value="C">Complete</option>
                                    </select>
                                </div>


                            </> : null}

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ------------------------------Filter modal----------------------------------- */}

            <div className="offcanvas offcanvas-end" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} tabIndex="-1" id="LeadsFilterCanvas" aria-labelledby="offcanvasRightLabel">
                <div className="modal-content">
                    <div className="modal-header px-3 pt-3" style={{ borderBottomColor: color.tableBorder }}>
                        <div className="modal_title" style={{ color: color.darkText }} id="offcanvasRightLabel">Filter User</div>
                        <span type="button" id='canvaCloseId' className='modal_close' data-bs-dismiss="offcanvas" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                    </div>
                </div>
                <hr style={{ borderTop: "0.5px solid " + color.tableBorder, opacity: '1' }} />
                <div className="offcanvas-body">

                    <div className="mb-3">
                        <label className='modal_label' style={{ color: color.darkText }}>Created By</label>
                        <Select
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={options}
                            onChange={(e) => setOwner(e)}
                            value={owner}
                        />
                    </div>

                    <div className="mb-4 mt-1">
                        <label className='modal_label' style={{ color: color.darkText }}>Status</label>
                        <select value={status} onChange={(e) => setStatus(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                            <option value="">-----select status-----</option>
                            <option value="P">Pending</option>
                            <option value="C">Completed</option>
                        </select>
                    </div>

                    <div className="mb-4 mt-1">
                        <label className='modal_label' style={{ color: color.darkText }}>Pay Filter</label>
                        <select value={pay} onChange={(e) => setPay(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                            <option value="">-----select status-----</option>
                            <option value="REIMBURSEMENT">REIMBURSEMENT</option>
                            <option value="CASHLESS">CASHLESS</option>
                        </select>
                    </div>

                    <label className='modal_label' style={{ color: color.lightText }}>Created Date Range</label>
                    <div className="mb-4 mt-1">
                        <label className='modal_label' style={{ color: color.darkText }}>Start Date</label>
                        <input type="date" value={dateRange.startDate} onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="mb-4">
                        <label className='modal_label' style={{ color: color.darkText }}>End Date</label>
                        <input type="date" value={dateRange.endDate} onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="pt-4">
                        <button className="primaryButton me-3" id='filterBtn' onClick={applyFilter}>Apply filter</button>
                        <button className="secondaryButton" onClick={clearFilter}>Clear filter</button>

                    </div>
                </div>
            </div>

            {isLoading && <Loading />}
        </>
    )
}

export default Claims