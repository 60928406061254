import React, { useState, useEffect, Suspense } from 'react'
import Ripples from 'react-ripples';
import { Icon } from '@iconify/react';
import { useToasts } from 'react-toast-notifications';
import Loading from './../../../Loading';
import ReactPaginate from 'react-paginate';
import Select, { createFilter, useStateManager } from 'react-select';
import makeAnimated from 'react-select/animated';
import { useColorStore } from './../../../Store/ColorStore';
import { useUserStore } from '../../../Store/UserStore/UserStore';
import { useLeadActionStore } from '../../../Store/LeadStore/LeadAction';
import { useAssignedLeadStore } from '../../../Store/LeadStore/AssignedLeadStore';
import { CSVLink } from "react-csv";
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { leadCategoryOptions, userSegrigation, userType } from '../../../Export';
import { useUserPermissionStore } from '../../../Store/UserPermissionStore/UserPermissionStore';
import { leadFollowUpOptions } from '../../../Export';
import AssignedLeadList from './../../Admin/Leads/AssignedLeads/AssignedLeadList';
import { useLeadImportStore } from '../../../Store/LeadStore/LeadImportStore';
const animatedComponents = makeAnimated();


const Motor = () => {
    let token = JSON.parse(localStorage.getItem('user'))
    const { importManualLeads } = useLeadImportStore()
    const location = useLocation()
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const { color } = useColorStore()
    const { users, fetchUsers } = useUserStore()
    const { fetchMotorLeads, updateAssignedLeadsList, motorLeadList, motorLeads, setMotorLeads, motorLeadsOther } = useAssignedLeadStore()
    const { restoreLeads, trashLeads, assignLeads } = useLeadActionStore()
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)

    const [searchInput, setSearchInput] = useState("")

    function search() {
        setReload(!reload)
    }

    useEffect(() => {
        if (users.length === 0) {
            fetchUsers()
        }
    }, [])

    // ------------------------------------------Permission Work---------------------------------------

    const [permission, setPermission] = useState(false)
    const [permission2, setPermission2] = useState(false)

    const { userPermissions } = useUserPermissionStore()
    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'LEADS_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission(d[x.name])
        }
    }, [userPermissions])

    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'OWNER_CHANGE_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission2(d[x.name])
        }
    }, [userPermissions])

    // ----------------------------------------------------------------------------Filteration work-------------------------------------------------------------------------------------------

    const [filterMode, setFilterMode] = useState(false)
    const [changeNotify, setChangeNotify] = useState(false)
    const [filterToggle, setFilterToggle] = useState(true)
    const [owner, setOwner] = useState([])
    const [filterServiceState, setFilterServiceState] = useState([])
    const [status, setStatus] = useState([])
    const [lead_c, setLead_c] = useState('')
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: '',
    })
    const [dateRange2, setDateRange2] = useState({
        startDate2: '',
        endDate2: '',
    })
    const [dateRange3, setDateRange3] = useState({
        startDate3: '',
        endDate3: '',
    })
    // -------Options-----------
    const [options, setOptions] = useState([])

    const leadServiceStateOptions = [
        { value: 'P', label: 'Paid' },
        { value: 'R', label: 'Renew' },
    ]

    useEffect(() => {
        setOptions([])
        let d = []
        for (let i = 0; i < users.length; i++) {
            const u = users[i];
            d.push({
                value: u.id, label: u.name
            })
        }
        setOptions(d)
    }, [users])

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: color.inputBackground,
            // match with the menu
            borderRadius: 4,
            // Overwrittes the different states of border
            borderColor: color.borderColor,
            fontSize: 14,
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: color.borderColor
            }
        }),
        menu: base => ({
            ...base,
            background: color.inputBackground,
            color: color.darkText,
            fontSize: 14,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);

            return {
                ...styles,
                backgroundColor: isFocused ? color.blue : null,
                color: isFocused ? "#fff" : null,
                // color: "#333333"
            };
        }
    };

    const handleRemoveOwner = (index) => {
        setOwner([
            ...owner.slice(0, index),
            ...owner.slice(index + 1, owner.length)
        ]);
    }
    const handleRemoveFilterServiceState = (index) => {
        setFilterServiceState([
            ...filterServiceState.slice(0, index),
            ...filterServiceState.slice(index + 1, filterServiceState.length)
        ]);
    }
    const handleRemoveFilterStatus = (index) => {
        setStatus([
            ...status.slice(0, index),
            ...status.slice(index + 1, status.length)
        ]);
    }

    useEffect(() => {
        if (location.search.length > 0 && options.length > 0) {
            const filterParams = new URLSearchParams(location.search);
            const nowner = filterParams.get('owner');
            const nfilterServiceState = filterParams.get('filterServiceState');
            const nstatus = filterParams.get('status');
            const nstartDate = filterParams.get('startDate');
            const nendDate = filterParams.get('endDate');
            const cstartDate = filterParams.get('startDate2');
            const cendDate = filterParams.get('endDate2');
            const vstartDate = filterParams.get('startDate3');
            const vendDate = filterParams.get('endDate3');
            const nsearch = filterParams.get('search');
            const nlead_c = filterParams.get('lead_c');
            setStatus(leadFollowUpOptions.filter((f) => {
                return nstatus.split(',').indexOf(f.value) > -1;
            }))
            setOwner(options.filter((f) => {
                return nowner.split(',').indexOf(String(f.value)) > -1;
            }))
            setFilterServiceState(leadServiceStateOptions.filter((f) => {
                return nfilterServiceState.split(',').indexOf(f.value) > -1;
            }))
            setDateRange({ ...dateRange, startDate: nstartDate, endDate: nendDate })
            setSearchInput(nsearch)
            setLead_c(nlead_c || '')
            setDateRange2({ ...dateRange2, startDate2: cstartDate, endDate2: cendDate })
            setDateRange3({ ...dateRange3, startDate3: vstartDate, endDate3: vendDate })
            setChangeNotify(true)
        }
        else {
            if (location.search.length === 0) {
                clearFilter()
            }
        }
    }, [location.search, options])

    const applyFilter = () => {
        setChangeNotify(false)
        if (owner.length > 0 || filterServiceState.length > 0 || status.length > 0 || lead_c.length > 0 || dateRange.startDate.length > 0 && dateRange.endDate.length > 0 || dateRange2.startDate2.length > 0 && dateRange2.endDate2.length > 0 || dateRange3.startDate3.length > 0 && dateRange3.endDate3.length > 0) {
            setFilterMode(true)
            setFilterToggle(!filterToggle)
            let u = `?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&owner=${owner.map((f) => { return f.value })}&filterServiceState=${filterServiceState.map((f) => { return f.value })}&status=${status.map((f) => { return f.value })}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&startDate2=${dateRange2.startDate2}&endDate2=${dateRange2.endDate2}&startDate3=${dateRange3.startDate3}&endDate3=${dateRange3.endDate3}&search=${searchInput}&lead_c=${lead_c}`
            navigate(`${u}`, { replace: true });
        } else {
            clearFilter()
        }
    }

    const clearFilter = () => {
        setOwner([])
        setFilterServiceState([])
        setStatus([])
        setDateRange({ ...dateRange, startDate: '', endDate: '' })
        setDateRange2({ ...dateRange2, startDate2: '', endDate2: '' })
        setDateRange3({ ...dateRange3, startDate3: '', endDate3: '' })
        navigate('', { replace: true })
        setFilterMode(false)
        setLead_c('')
        setReload(!reload)
    }

    useEffect(() => {
        if (changeNotify) {
            applyFilter()
        }
    }, [changeNotify])

    // ----------------Checked Ids Work---------------------------

    const [checked, setChecked] = useState([]);
    const handleCheckAllChange = (e) => {
        setChecked(
            e.target.checked ? motorLeads.map((c) => c.id) : []
        );
    };

    const handleSingleChange = (e, c) => {
        setChecked((prevChecked) =>
            e.target.checked
                ? [...prevChecked, c.id]
                : prevChecked.filter((item) => item !== c.id)
        );
    };

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (motorLeadsOther) {
            const endOffset = itemOffset + itemsPerPage;
            setPageCount(motorLeadsOther.count / itemsPerPage);
            setItemEndSet(endOffset)
            setitemLength(motorLeadsOther.count)
        }
    }, [motorLeadsOther]);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
        const newOffset = (event.selected * itemsPerPage) % motorLeadsOther.count;
        setItemOffset(newOffset);
    };

    // ******************pagination work ends here***************************************

    const handleLeadRestore = () => {
        if (checked.length > 0) {
            setIsLoading(true)
            restoreLeads(checked)
                .then(res => {
                    setReload(!reload)
                    setChecked([])
                    setIsLoading(false)
                    addToast('Lead Restore Success...', { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })

        } else {
            addToast('Please select leads to restore...', { appearance: 'error' });
        }
    }

    const handleLeadTrash = () => {
        if (checked.length > 0) {
            let d = document.getElementById("dltBtn")
            d.click()
            setIsLoading(true)
            trashLeads(checked)
                .then(res => {
                    setChecked([])
                    setReload(!reload)
                    setIsLoading(false)
                    addToast('Leads Sent to Trash...', { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })

        } else {
            addToast('Please select leads to trash...', { appearance: 'error' });
        }
    }

    const [assignTo, setAssignTo] = useState("")

    const handleLeadAssign = () => {
        if (checked.length > 0 && assignTo.length > 0) {
            let d = document.getElementById("dltBtn")
            d.click()
            setIsLoading(true)
            let ndata = {
                checked: checked,
                assign_to: assignTo
            }
            assignLeads(ndata)
                .then(res => {
                    setChecked([])
                    setReload(!reload)
                    setIsLoading(false)
                    addToast('Leads assigned...', { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })

        } else {
            addToast('Please select user to assign leads...', { appearance: 'error' });
        }
    }

    // *************************************************Main Url Work****************************************************

    useEffect(() => {
        let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&owner=${owner.map((f) => { return f.value })}&filterServiceState=${filterServiceState.map((f) => { return f.value })}&status=${status.map((f) => { return f.value })}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&startDate2=${dateRange2.startDate2}&endDate2=${dateRange2.endDate2}&startDate3=${dateRange3.startDate3}&endDate3=${dateRange3.endDate3}&search=${searchInput}&lead_c=${lead_c}`
        setIsLoading(true)
        fetchMotorLeads(u)
            .then(res => {
                setIsLoading(false)
                setMotorLeads(res)
            })
            .catch(err => {
                setIsLoading(false)
            })
    }, [reload, itemsPerPage, currentPage, filterToggle])

    // -------------------------Assign Work-----------------------------------

    const [payload, setPayload] = useState({
        lead_c: 'MOTOR',
        assign_to: token.id,
        name: '',
        email: '',
        contact: '',
        lead_source: 'OT',
        follow_up_state: '',
        last_follow_up: '',
        callback_date: null,
        due_date: null,
    })

    const [error2, setError2] = useState({
        name: '',
        contact: '',
        email: '',
        lead_source: ''
    })

    const handleValidation = () => {
        let formIsValid = true;
        let errors = { ...error2 }
        if (payload.lead_source.length === 0) {
            formIsValid = false;
            errors.lead_source = 'Required...!'
        } else {
            errors.lead_source = ''
        }
        if (payload.name.length === 0) {
            formIsValid = false;
            errors.name = 'Required...!'
        } else {
            errors.name = ''
        }
        if (payload.contact.length !== 10) {
            formIsValid = false;
            errors.contact = 'Please enter valid contact number...!'
        } else {
            errors.contact = ''
        }

        setError2(errors)
        return formIsValid
    }

    const handleSubmit2 = () => {
        if (handleValidation()) {
            if (!payload.callback_date || payload.follow_up_state.length === 0 || payload.last_follow_up.length === 0) {
                addToast("Please enter all followup details.", { appearance: 'error' });
                return
            }
            setIsLoading(true)
            importManualLeads(payload)
                .then(res => {
                    setIsLoading(false)
                    addToast('Lead Import Success...', { appearance: 'success' });
                    setPayload({ ...payload, name: '', lead_source: '', email: '', assign_to: token.id, contact: '', follow_up_state: '', last_follow_up: '', callback_date: null, due_date: null })
                    setReload(!reload)
                })
                .catch(err => {
                    setIsLoading(false)
                    if (err.response) {
                        if (err.response.status === 400) {
                            addToast(err.response.data, { appearance: 'error' });
                        }
                    } else {
                        addToast('Lead Import Error...', { appearance: 'error' });
                    }
                })
        } else {

        }
    }

    return (
        <>

            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search d-flex align_center" style={{ position: 'relative' }}>
                        <div>
                            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
                            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                        </div>
                        <div className='ms-2'>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block mb-0' during={650}>
                                <button onClick={search} className="primaryButton "><Icon icon="ion:search-outline" fontSize={18} /></button>
                            </Ripples>
                        </div>
                    </div>
                    <div className="myrow">
                        <Ripples color='rgba(255,255,255,0.2)' className='dis_block me-2' during={650}>
                            <button data-bs-toggle="modal" data-bs-target="#manualImportModal" className="primaryButton" style={{ marginRight: '1px' }}><Icon icon="mingcute:add-fill" fontSize={18} />
                            </button>
                        </Ripples>
                        {permission2 || token.user_type === userType.admin ?
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={650}>
                                <button data-bs-toggle="modal" data-bs-target="#leadAssignModal" className="primaryButton right_border_radius" disabled={checked.length === 0} style={{ marginRight: '1px' }}><Icon icon="mingcute:user-edit-line" fontSize={18} /></button>
                            </Ripples>
                            :
                            null
                        }
                        {permission2 &&
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={650}>
                                <button data-bs-toggle="modal" data-bs-target="#leadRestoreModal" disabled={checked.length === 0} className="primaryButton left_border_radius right_border_radius" style={{ marginRight: '1px' }}><Icon icon="ic:round-settings-backup-restore" fontSize={18} /></button>
                            </Ripples>
                        }

                        {permission &&
                            <Ripples color='rgba(255,255,255,0.2)' className='me-4 dis_block' during={650}>
                                <button data-bs-toggle="modal" data-bs-target="#leadTrashModal" disabled={checked.length === 0} className="primaryButton left_border_radius" ><Icon icon="basil:trash-solid" fontSize={18} /></button>
                            </Ripples>
                        }

                        {token.user_type === userType.admin ? <>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={800}>
                                <button data-toggle="tooltip" data-placement="top" title="Tooltip on top" className="infoButton right_border_radius" data-bs-toggle="offcanvas" data-bs-target="#LeadsFilterCanvas" aria-controls="LeadsFilterCanvas" ><Icon icon="mdi:filter" fontSize={15} /> Filter</button>
                            </Ripples>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={800}>
                                <CSVLink data={motorLeads} filename='All-Leads.csv'><button className="successButton left_border_radius right_border_radius"><Icon icon="ph:export-fill" fontSize={16} /> Export</button></CSVLink>
                            </Ripples>
                        </>
                            : <>

                                <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={800}>
                                    <button data-toggle="tooltip" data-placement="top" title="Tooltip on top" className="infoButton" data-bs-toggle="offcanvas" data-bs-target="#LeadsFilterCanvas" aria-controls="LeadsFilterCanvas" ><Icon icon="mdi:filter" fontSize={15} /> Filter</button>
                                </Ripples>
                            </>
                        }

                    </div>
                </div>

                {filterMode &&
                    <div className='filter_values_wrapper'>
                        <div className="filter_values_title" style={{ color: color.darkText }}>Filter by &nbsp;:<span onClick={clearFilter} className="clear_filter_link">Clear filter</span></div>

                        {status.length > 0 ?
                            status.map((u, count) => {
                                return <div className="filter_values" key={count}>{u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveFilterStatus(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            })
                            : null
                        }
                        {owner.length > 0 ?
                            owner.map((u, count) => {
                                return <div className="filter_values" key={count}>{u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveOwner(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            })
                            : null
                        }
                        {filterServiceState.length > 0 ?
                            filterServiceState.map((u, count) => {
                                return <div className="filter_values" key={count}>{u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveFilterServiceState(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            })
                            : null
                        }
                        {dateRange.startDate.length > 0 && dateRange.endDate.length > 0 &&
                            <div className="filter_values">
                                {moment(dateRange.startDate).format('ll')} - {moment(dateRange.endDate).format('ll')} <Icon icon="fa6-solid:x" onClick={() => { setDateRange({ ...dateRange, startDate: '', endDate: '' }); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
                            </div>
                        }
                        {dateRange2.startDate2.length > 0 && dateRange2.endDate2.length > 0 &&
                            <div className="filter_values">
                                {"Callback : " + moment(dateRange2.startDate2).format('ll')} - {moment(dateRange2.endDate2).format('ll')} <Icon icon="fa6-solid:x" onClick={() => { setDateRange2({ ...dateRange2, startDate2: '', endDate2: '' }); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
                            </div>
                        }
                        {dateRange3.startDate3.length > 0 && dateRange3.endDate3.length > 0 &&
                            <div className="filter_values">
                                {"Due Date : " + moment(dateRange3.startDate3).format('ll')} - {moment(dateRange3.endDate3).format('ll')} <Icon icon="fa6-solid:x" onClick={() => { setDateRange3({ ...dateRange3, startDate3: '', endDate3: '' }); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
                            </div>
                        }
                        {lead_c?.length > 0 &&
                            <div className="filter_values">
                                {lead_c} <Icon icon="fa6-solid:x" onClick={() => { setLead_c(''); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
                            </div>
                        }

                    </div>
                }

                <div className="table-responsive mt-3">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '75px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                            <option value={10}>10</option>
                            <option value={1}>1</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        &nbsp; Out of - {motorLeadsOther ? motorLeadsOther.count : 0}
                    </div>
                    {checked.length > 0 &&
                        <div style={{ color: color.blue2, fontSize: "13px", marginBottom: '10px' }}>
                            {checked.length} Entries are Selected. <u onClick={() => setChecked([])} style={{ color: color.red, cursor: 'pointer' }}> Clear all </u>
                        </div>
                    }
                    <table className="table table-nowrap align-middle mb-3 mt-3 table-bordered table-striped">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '40px', color: color.darkText }}>
                                    <input checked={motorLeads.every((e, index) => e.id === checked[index]) && itemLength > 0} onChange={handleCheckAllChange} type="checkbox" className="form-check-input" style={{ backgroundColor: color.inputBackground, border: "1px solid " + color.borderColor }} />
                                    {/* <label className="form-check-label" for="contacusercheck"></label> */}
                                </th>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Category</th>
                                <th style={{ color: color.darkText }} scope="col">Name</th>
                                {/* <th style={{ color: color.darkText }} scope="col">Contact Info</th> */}
                                <th style={{ color: color.darkText }} scope="col">Lead Owner</th>
                                <th style={{ color: color.darkText }} scope="col">Due Date</th>
                                <th style={{ color: color.darkText }} scope="col">Status</th>
                                <th style={{ color: color.darkText }} scope="col">Next Callback</th>
                                <th style={{ color: color.darkText }} scope="col">Feedback</th>
                                {/* <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th> */}
                            </tr>
                        </thead>

                        <tbody>
                            {motorLeads.length > 0 ?
                                motorLeads.map((c, count) => {
                                    return <Suspense key={c.id} fallback={<></>}>
                                        <AssignedLeadList motor={true} reload={reload} setReload={setReload} assignedLeadsList={motorLeadList} checked={checked} updateAssignedLeadsList={updateAssignedLeadsList} handleSingleChange={handleSingleChange} data={c} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                    </Suspense>
                                })
                                :
                                <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>
                            }
                        </tbody>
                    </table>
                    {motorLeads.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />

                </div>

            </div>

            {/* ------------------------------Filter modal----------------------------------- */}

            <div className="offcanvas offcanvas-end" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} tabIndex="-1" id="LeadsFilterCanvas" aria-labelledby="offcanvasRightLabel">
                <div className="modal-content">
                    <div className="modal-header px-3 pt-3" style={{ borderBottomColor: color.tableBorder }}>
                        <div className="modal_title" style={{ color: color.darkText }} id="offcanvasRightLabel">Filter User</div>
                        <span type="button" id='canvaCloseId' className='modal_close' data-bs-dismiss="offcanvas" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>

                    </div>
                </div>
                <hr style={{ borderTop: "0.5px solid " + color.tableBorder, opacity: '1' }} />
                <div className="offcanvas-body">


                    <div className="mb-4">
                        <label className='modal_label' style={{ color: color.darkText }}>Lead Owner</label>
                        <Select
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={options}
                            onChange={(e) => setOwner(e)}
                            value={owner}
                        />
                    </div>

                    <div className="mb-4">
                        <label className='modal_label' style={{ color: color.darkText }}>Follow Up</label>
                        <Select
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={leadFollowUpOptions}
                            onChange={(e) => setStatus(e)}
                            value={status}
                        />
                    </div>

                    <label className='modal_label' style={{ color: color.lightText }}>Callback Date Range</label>
                    <div className="myrow justify_space_between">
                        <div className="mb-3" style={{ width: '49%' }}>
                            <label className='modal_label' style={{ color: color.darkText }}>Start Date</label>
                            <input type="date" value={dateRange2.startDate2} onChange={(e) => setDateRange2({ ...dateRange2, startDate2: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                        </div>
                        <div className="mb-3" style={{ width: '49%' }}>
                            <label className='modal_label' style={{ color: color.darkText }}>End Date</label>
                            <input type="date" value={dateRange2.endDate2} onChange={(e) => setDateRange2({ ...dateRange2, endDate2: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                        </div>
                    </div>

                    <label className='modal_label' style={{ color: color.lightText }}>Due Date Range</label>
                    <div className="myrow justify_space_between">
                        <div className="mb-3" style={{ width: '49%' }}>
                            <label className='modal_label' style={{ color: color.darkText }}>Start Date</label>
                            <input type="date" value={dateRange3.startDate3} onChange={(e) => setDateRange3({ ...dateRange3, startDate3: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                        </div>
                        <div className="mb-3" style={{ width: '49%' }}>
                            <label className='modal_label' style={{ color: color.darkText }}>End Date</label>
                            <input type="date" value={dateRange3.endDate3} onChange={(e) => setDateRange3({ ...dateRange3, endDate3: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                        </div>
                    </div>

                    <label className='modal_label' style={{ color: color.lightText }}>Assign Leads Date Range</label>
                    <div className="mb-4 mt-1">
                        <label className='modal_label' style={{ color: color.darkText }}>Start Date</label>
                        <input type="date" value={dateRange.startDate} onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="mb-4">
                        <label className='modal_label' style={{ color: color.darkText }}>End Date</label>
                        <input type="date" value={dateRange.endDate} onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="pt-4">
                        <button className="primaryButton me-3" id='filterBtn' onClick={applyFilter}>Apply filter</button>
                        <button className="secondaryButton" onClick={clearFilter}>Clear filter</button>

                    </div>
                </div>
            </div>

            {/* ------------------------------Delete modal----------------------------------- */}

            <div className="modal fade" id={"leadTrashModal"} tabIndex="-1" aria-labelledby="leadTrashModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to Trash selected Leads ?</div>
                        </div>
                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id="dltBtn" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleLeadTrash}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Proceed"}</button>
                        </div>
                    </div>
                </div>
            </div>


            {/* ------------------------------Lead Assign modal----------------------------------- */}

            <div className="modal fade" id="leadAssignModal" tabIndex="-1" aria-labelledby="leadAssignModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div className="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Change Owner</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Name <span className="text-danger">*</span></label>
                                <select value={assignTo} onChange={(e) => setAssignTo(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">----------Select User to Assign-------------</option>
                                    {users.map((d) => {
                                        return <option value={d.id} key={d.id}>{d.name}</option>
                                    })}
                                </select>
                            </div>

                        </div>
                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleLeadAssign}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Save"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ------------------------------Lead Restore modal----------------------------------- */}

            <div className="modal fade" id={"leadRestoreModal"} tabIndex="-1" aria-labelledby="leadRestoreModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>
                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to Restore these Leads ? These leads will be taken back from the Lead Owner.</div>
                        </div>
                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id="restoreBtn" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleLeadRestore}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Restore"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------------Manual Import Upload Modal---------------------------- */}

            <div className="modal fade" id="manualImportModal" tabindex="-1" aria-labelledby="manualImportModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div className="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }} id="manualImportModalLabel">Add New</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Mis Category <span className="text-danger">*</span></label>
                                <select value={payload.lead_c} onChange={(e) => setPayload({ ...payload, lead_c: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    {leadCategoryOptions.map((m, c) => {
                                        return <option key={c} value={m.value}>{m.label}</option>
                                    })}
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Lead Owner <span className="text-danger">*</span></label>
                                <select value={payload.assign_to} onChange={(e) => setPayload({ ...payload, assign_to: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    {users.map((m, c) => {
                                        return <option key={c} value={m.id}>{m.name}</option>
                                    })}
                                </select>
                            </div>


                            <div className="mb-1">
                                <label className='modal_label' style={{ color: color.darkText }}>Name <span className="text-danger">*</span></label>
                                <input value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                {error2.name.length > 0 && <div className="text-danger" style={{ fontSize: '13px' }}>{error2.name}</div>}
                            </div>

                            <div className="mb-1">
                                <label className='modal_label' style={{ color: color.darkText }}>Contact <span className="text-danger">*</span></label>
                                <input value={payload.contact} onChange={(e) => setPayload({ ...payload, contact: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                {error2.contact.length > 0 && <div className="text-danger" style={{ fontSize: '13px' }}>{error2.contact}</div>}
                            </div>

                            <div className="mb-1">
                                <label className='modal_label' style={{ color: color.darkText }}>Email</label>
                                <input value={payload.email} onChange={(e) => setPayload({ ...payload, email: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                {error2.email.length > 0 && <div className="text-danger" style={{ fontSize: '13px' }}>{error2.email}</div>}
                            </div>

                            <div className='mb-1'>
                                <label className='modal_label' style={{ color: color.darkText }}>Status <span className="text-danger">*</span></label>
                                <select value={payload.follow_up_state} onChange={(e) => setPayload({ ...payload, follow_up_state: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">-----Select Status-----</option>
                                    {leadFollowUpOptions.map((d) => {
                                        return <option value={d.value}>{d.label}</option>
                                    })}
                                </select>
                            </div>

                            <div className='mb-1'>
                                <label className='modal_label' style={{ color: color.darkText }}>Next Callback Date <span className="text-danger">*</span></label>
                                <input type='datetime-local' value={payload.callback_date} onChange={(e) => setPayload({ ...payload, callback_date: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                            {payload.lead_c === 'MOTOR' &&
                                <div className='mb-1'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Due Date</label>
                                    <input type='date' value={payload.due_date} onChange={(e) => setPayload({ ...payload, due_date: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>
                            }

                            <div className='mb-1'>
                                <label className='modal_label' style={{ color: color.darkText }}>Remark <span className="text-danger">*</span></label>
                                <textarea value={payload.last_follow_up} onChange={(e) => setPayload({ ...payload, last_follow_up: e.target.value })} placeholder='Enter...' rows={3} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'></textarea>
                            </div>

                        </div>

                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit2}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Import"}</button>
                        </div>
                    </div>
                </div>
            </div>


            {isLoading && <Loading />}
        </>
    )
}

export default Motor