import React, { useState, useEffect, Suspense } from 'react'
import Ripples from 'react-ripples';
import { Icon } from '@iconify/react';
import { useToasts } from 'react-toast-notifications';
import ReactPaginate from 'react-paginate';
import Select, { createFilter, useStateManager } from 'react-select';
import makeAnimated from 'react-select/animated';
import { useColorStore } from '../../../Store/ColorStore';
import { CSVLink } from "react-csv";
import moment from 'moment';
import Loading from './../../../Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserStore } from './../../../Store/UserStore/UserStore';
import { useClaimStore } from './../../../Store/ClaimStore/ClaimStore';
import { numFormatter, productOptions, userType } from '../../../Export';
import { useRenewStore } from './../../../Store/RenewStore/RenewStore';
const RenewalList = React.lazy(() => { return import('./RenewalList') });
const animatedComponents = makeAnimated();

const Renewal = () => {
    let token = JSON.parse(localStorage.getItem('user'))
    let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
    const location = useLocation()
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const { color } = useColorStore()
    const { users, fetchUsers } = useUserStore()
    const { renewal, fetchRenewal, setRenewal, renewalOther, importRenewData } = useRenewStore()
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    
    function search() {
        setReload(!reload)
    }

    useEffect(() => {
        if (users.length === 0) {
            fetchUsers()
        }
    }, [])


    // ----------------------------------------------------------------------------Filteration work-------------------------------------------------------------------------------------------

    const [filterMode, setFilterMode] = useState(false)
    const [changeNotify, setChangeNotify] = useState(false)
    const [filterToggle, setFilterToggle] = useState(true)
    const [owner, setOwner] = useState([])
    const [filterServiceState, setFilterServiceState] = useState([])
    const [status, setStatus] = useState([])
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: '',
    })
    const [dateRange2, setDateRange2] = useState({
        startDate2: '',
        endDate2: '',
    })
    // -------Options-----------
    const [options, setOptions] = useState([])

    const leadServiceStateOptions = [
        { value: 'P', label: 'Paid' },
        { value: 'R', label: 'Renew' },
    ]
    const statusOptions = [
        { value: 'PDR', label: 'Paid' },
        { value: 'P', label: 'Processing' },
        { value: 'U', label: 'Unpaid' },
        { value: 'L', label: 'Lost' },
    ]

    useEffect(() => {
        setOptions([])
        let d = []
        for (let i = 0; i < users.length; i++) {
            const u = users[i];
            d.push({
                value: u.id, label: u.name
            })
        }
        setOptions(d)
    }, [users])

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: color.inputBackground,
            // match with the menu
            borderRadius: 4,
            // Overwrittes the different states of border
            borderColor: color.borderColor,
            fontSize: 14,
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: color.borderColor
            }
        }),
        menu: base => ({
            ...base,
            background: color.inputBackground,
            color: color.darkText,
            fontSize: 14,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);

            return {
                ...styles,
                backgroundColor: isFocused ? color.blue : null,
                color: isFocused ? "#fff" : null,
                // color: "#333333"
            };
        }
    };

    const handleRemoveOwner = (index) => {
        setOwner([
            ...owner.slice(0, index),
            ...owner.slice(index + 1, owner.length)
        ]);
    }
    const handleRemoveFilterServiceState = (index) => {
        setFilterServiceState([
            ...filterServiceState.slice(0, index),
            ...filterServiceState.slice(index + 1, filterServiceState.length)
        ]);
    }
    const handleRemoveFilterStatus = (index) => {
        setStatus([
            ...status.slice(0, index),
            ...status.slice(index + 1, status.length)
        ]);
    }

    useEffect(() => {
        if (location.search.length > 0 && options.length > 0) {
            const filterParams = new URLSearchParams(location.search);
            const nstatus = filterParams.get('status');
            const nowner = filterParams.get('owner');
            const nstartDate = filterParams.get('startDate');
            const nendDate = filterParams.get('endDate');
            const nstartDate2 = filterParams.get('startDate2');
            const nendDate2 = filterParams.get('endDate2');
            const nsearch = filterParams.get('search');
            setStatus(statusOptions.filter((f) => {
                return nstatus.split(',').indexOf(String(f.value)) > -1;
            }))
            setOwner(productOptions.filter((f) => {
                return nowner.split(',').indexOf(String(f.value)) > -1;
            }))
            setDateRange({ ...dateRange, startDate: nstartDate, endDate: nendDate })
            setDateRange2({ ...dateRange2, startDate2: nstartDate2, endDate2: nendDate2 })
            setSearchInput(nsearch)
            setChangeNotify(true)
        }
        else {
            if (location.search.length === 0) {
                clearFilter()
            }
        }
    }, [location.search, options])

    const applyFilter = () => {
        setChangeNotify(false)
        if (owner.length > 0 || filterServiceState.length > 0 || status.length > 0 || dateRange.startDate.length > 0 && dateRange.endDate.length > 0 || dateRange2.startDate2.length > 0 && dateRange2.endDate2.length > 0) {
            setFilterMode(true)
            setFilterToggle(!filterToggle)
            let u = `?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&status=${status.map((f) => { return f.value })}&owner=${owner.map((f) => { return f.value })}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&startDate2=${dateRange2.startDate2}&endDate2=${dateRange2.endDate2}&search=${searchInput}`
            navigate(`${u}`, { replace: true });
        } else {
            clearFilter()
        }
    }

    const clearFilter = () => {
        setOwner([])
        setFilterServiceState([])
        setStatus([])
        setDateRange({ ...dateRange, startDate: '', endDate: '' })
        setDateRange2({ ...dateRange2, startDate2: '', endDate2: '' })
        navigate('', { replace: true })
        setFilterMode(false)
        setReload(!reload)
    }

    useEffect(() => {
        if (changeNotify) {
            applyFilter()
        }
    }, [changeNotify])

    // ----------------Checked Ids Work---------------------------

    const [checked, setChecked] = useState([]);
    const handleCheckAllChange = (e) => {
        setChecked(
            e.target.checked ? renewal.map((c) => c.id) : []
        );
    };

    const handleSingleChange = (e, c) => {
        setChecked((prevChecked) =>
            e.target.checked
                ? [...prevChecked, c.id]
                : prevChecked.filter((item) => item !== c.id)
        );
    };

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (renewalOther) {
            const endOffset = itemOffset + itemsPerPage;
            setPageCount(renewalOther.count / itemsPerPage);
            setItemEndSet(endOffset)
            setitemLength(renewalOther.count)
        }
    }, [renewalOther]);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
        const newOffset = (event.selected * itemsPerPage) % renewalOther.count;
        setItemOffset(newOffset);
    };

    // ******************pagination work ends here***************************************

    // *************************************************Main Url Work****************************************************
    const [rtotals, setRtotals] = useState()
    useEffect(() => {
        if (location.search.length > 0) {
            if (filterMode) {
                let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&productState=${owner.map((f) => { return f.value })}&status=${status.map((f) => { return f.value })}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&startDate2=${dateRange2.startDate2}&endDate2=${dateRange2.endDate2}&search=${searchInput}`
                setIsLoading(true)
                fetchRenewal(u)
                    .then(res => {
                        setIsLoading(false)
                        setRenewal(res)
                        setRtotals({
                            tr: res.tr,
                            pr: res.pr,
                            pdr: res.pdr,
                            lr: res.lr,
                            ur: res.ur,
                        })
                    })
                    .catch(err => {
                        setIsLoading(false)
                    })
            }
        }
        else {
            let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&search=${searchInput}`
            setIsLoading(true)
            fetchRenewal(u)
                .then(res => {
                    setIsLoading(false)
                    setRenewal(res)
                    setRtotals({
                        tr: res.tr,
                        pr: res.pr,
                        pdr: res.pdr,
                        lr: res.lr,
                        ur: res.ur,
                    })
                })
                .catch(err => {
                    setIsLoading(false)
                })
        }
    }, [reload, itemsPerPage, currentPage, filterToggle])

    // *******************************

    const [csvFile, setCsvFile] = useState()
    let alist = [{
        insured_person: '',
        policy_number: '',
        contact: '',
        alt_contact: '',
        end_date: '',
        sum_assured: '',
        net_premium: '',
        gross_premium: '',
        migration_premium: '',
        age: '',
        product: '',
        status: '',
    }]

    const handleSubmit = () => {
        if (csvFile) {
            setIsLoading(true)
            let formData = new FormData()
            formData.append('file', csvFile)
            importRenewData(formData)
                .then(res => {
                    document.getElementById('dltBtn2').click()
                    setIsLoading(false)
                    setCsvFile(null)
                    setReload(!reload)
                    addToast('Success...', { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    if (err.response && err.response.data) {
                        addToast(err.response.data, { appearance: 'error' });
                    }
                    else {
                        addToast('Error...', { appearance: 'error' });
                    }
                })
        }
    }

    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search d-flex align_center" style={{ position: 'relative' }}>
                        <div>
                            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
                            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                        </div>
                        <div className='ms-2'>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block mb-0' during={650}>
                                <button onClick={search} className="primaryButton "><Icon icon="ion:search-outline" fontSize={18} /></button>
                            </Ripples>
                        </div>
                    </div>
                    <div className="myrow">
                        {token.user_type === userType.admin &&
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block me-2' during={800}>
                                <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#uploadModal" >Upload Data</button>
                            </Ripples>
                        }
                        <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={800}>
                            <button data-toggle="tooltip" data-placement="top" title="Filter" className="infoButton" data-bs-toggle="offcanvas" data-bs-target="#LeadsFilterCanvas" aria-controls="LeadsFilterCanvas" ><Icon icon="mdi:filter" fontSize={15} /> Filter</button>
                        </Ripples>
                    </div>
                </div>

                {filterMode &&
                    <div className='filter_values_wrapper'>
                        <div className="filter_values_title" style={{ color: color.darkText }}>Filter by &nbsp;:<span onClick={clearFilter} className="clear_filter_link">Clear filter</span></div>

                        {status.length > 0 ?
                            status.map((u, count) => {
                                return <div className="filter_values" key={count}>{u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveFilterStatus(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            })
                            : null
                        }

                        {owner.length > 0 ?
                            owner.map((u, count) => {
                                return <div className="filter_values" key={count}>{u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveOwner(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            })
                            : null
                        }

                        {dateRange.startDate.length > 0 && dateRange.endDate.length > 0 &&
                            <div className="filter_values">
                                Expiry - {moment(dateRange.startDate).format('ll')} - {moment(dateRange.endDate).format('ll')} <Icon icon="fa6-solid:x" onClick={() => { setDateRange({ ...dateRange, startDate: '', endDate: '' }); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
                            </div>
                        }

                        {dateRange2.startDate2.length > 0 && dateRange2.endDate2.length > 0 &&
                            <div className="filter_values">
                                Callback - {moment(dateRange2.startDate2).format('ll')} - {moment(dateRange2.endDate2).format('ll')} <Icon icon="fa6-solid:x" onClick={() => { setDateRange2({ ...dateRange2, startDate2: '', endDate2: '' }); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
                            </div>
                        }

                    </div>
                }

                <div className="table-responsive mt-2">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '75px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                            <option value={10}>10</option>
                            <option value={1}>1</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        &nbsp; Out of - {renewalOther ? renewalOther.count : 0}
                    </div>
                    {rtotals &&
                        <div className="myrow">
                            <div style={{ color: color.darkText, fontSize: 16, marginBottom: '7px', paddingRight: '8px', borderRight: '1px solid ' + color.borderBottomColor }}>
                                Total Renewal - <span style={{ color: color.darkText }}>₹{numFormatter(rtotals.tr)}</span>
                            </div>
                            <div style={{ color: color.darkText, fontSize: 16, marginBottom: '7px', paddingLeft: '8px', }}>
                                Processed Renewal - <span style={{ color: color.blue2 }}>₹{numFormatter(rtotals.pr)}</span>
                            </div>
                            <div style={{ color: color.darkText, fontSize: 16, marginBottom: '7px', paddingLeft: '8px', }}>
                                Paid Renewal - <span style={{ color: color.green }}>₹{numFormatter(rtotals.pdr)}</span>
                            </div>
                            <div style={{ color: color.darkText, fontSize: 16, marginBottom: '7px', paddingLeft: '8px', }}>
                                Unpaid Renewal - <span style={{ color: color.yellow }}>₹{numFormatter(rtotals.ur)}</span>
                            </div>
                            <div style={{ color: color.darkText, fontSize: 16, marginBottom: '7px', paddingLeft: '8px', }}>
                                Lost Renewal - <span style={{ color: color.red }}>₹{numFormatter(rtotals.lr)}</span>
                            </div>
                        </div>
                    }

                    <table className="table table-nowrap align-middle my-1 table-bordered table-striped">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Insured Name</th>
                                <th style={{ color: color.darkText }} scope="col">Policy Number & Status</th>
                                <th style={{ color: color.darkText }} scope="col">Contact Number</th>
                                <th style={{ color: color.darkText }} scope="col">Product</th>
                                <th style={{ color: color.darkText }} scope="col">Expiry Date</th>
                                <th style={{ color: color.darkText }} scope="col">Sum Insured</th>
                                <th style={{ color: color.darkText }} scope="col">Gross Premium</th>
                                <th style={{ color: color.darkText }} scope="col">Net Premium</th>
                                <th style={{ color: color.darkText }} scope="col">Migration Premium</th>
                                <th style={{ color: color.darkText }} scope="col">New Policy Number</th>
                                <th style={{ color: color.darkText }} scope="col">Next Callback</th>
                                <th style={{ color: color.darkText }} scope="col">Remark</th>
                                <th scope="col" style={{ color: color.darkText }}>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {renewal.length > 0 ?
                                renewal.map((c, count) => {
                                    return <Suspense key={c.id} fallback={<></>}>
                                        <RenewalList reload={reload} setReload={setReload} users={users} checked={checked} data={c} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                    </Suspense>
                                })
                                :
                                <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>
                            }
                        </tbody>
                    </table>
                    {renewal.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />

                </div>

            </div>

            {/* ------------------------------Add modal----------------------------------- */}

            <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Upload Data</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Attach File (.csv,.xlsx) <span className="text-danger">*</span></label>
                                <input type='file' value={csvFile ? csvFile.filename : ''} onChange={(e) => setCsvFile(e.target.files[0])} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                            {<CSVLink data={alist} filename={"sample.csv"}><div className='text-start'> <span style={{ fontSize: '14px', color: color.blue2 }}>Click here to Download CSV Format... </span></div></CSVLink>}
                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id='dltBtn2' style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ------------------------------Filter modal----------------------------------- */}

            <div className="offcanvas offcanvas-end" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} tabIndex="-1" id="LeadsFilterCanvas" aria-labelledby="offcanvasRightLabel">
                <div className="modal-content">
                    <div className="modal-header px-3 pt-3" style={{ borderBottomColor: color.tableBorder }}>
                        <div className="modal_title" style={{ color: color.darkText }} id="offcanvasRightLabel">Filter</div>
                        <span type="button" id='canvaCloseId' className='modal_close' data-bs-dismiss="offcanvas" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                    </div>
                </div>
                <hr style={{ borderTop: "0.5px solid " + color.tableBorder, opacity: '1' }} />
                <div className="offcanvas-body">

                    <div className="mb-3">
                        <label className='modal_label' style={{ color: color.darkText }}>Product</label>
                        <Select
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={productOptions}
                            onChange={(e) => setOwner(e)}
                            value={owner}
                        />

                    </div>

                    <div className="mb-4 mt-1">
                        <label className='modal_label' style={{ color: color.darkText }}>Status</label>
                        <Select
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={statusOptions}
                            onChange={(e) => setStatus(e)}
                            value={status}
                        />
                    </div>

                    <label className='modal_label' style={{ color: color.lightText }}>Expiry Date Range</label>
                    <div className="mb-4 mt-1">
                        <label className='modal_label' style={{ color: color.darkText }}>Start Date</label>
                        <input type="date" value={dateRange.startDate} onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="mb-4">
                        <label className='modal_label' style={{ color: color.darkText }}>End Date</label>
                        <input type="date" value={dateRange.endDate} onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <label className='modal_label' style={{ color: color.lightText }}>Callback Date Range</label>
                    <div className="mb-4 mt-1">
                        <label className='modal_label' style={{ color: color.darkText }}>Start Date</label>
                        <input type="date" value={dateRange2.startDate2} onChange={(e) => setDateRange2({ ...dateRange2, startDate2: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="mb-4">
                        <label className='modal_label' style={{ color: color.darkText }}>End Date</label>
                        <input type="date" value={dateRange2.endDate2} onChange={(e) => setDateRange2({ ...dateRange2, endDate2: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="pt-4">
                        <button className="primaryButton me-3" id='filterBtn' onClick={applyFilter}>Apply filter</button>
                        <button className="secondaryButton" onClick={clearFilter}>Clear filter</button>

                    </div>
                </div>
            </div>

            {isLoading && <Loading />}
        </>
    )
}

export default Renewal