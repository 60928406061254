import React, { useRef, useEffect, useState } from 'react'
import { useColorStore } from '../../Store/ColorStore';
import * as echarts from 'echarts';
import { Icon } from '@iconify/react';
import Ripples from 'react-ripples';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import moment from 'moment';
import { Months, numFormatter, userType } from './../../Export';
import { AdminPath } from './../Admin/AdminRoutes/AdminPath';
import { useFieldDashboardStore } from '../../Store/DashboardStore.js/FieldDashboardStore';


const RenewalDashboard = () => {
    let token = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    const [today, setToday] = useState(moment().format('YYYY-MM-DD'))
    const [tommorow, setTommorow] = useState(moment().add(1, 'days').format('YYYY-MM-DD'))
    const [startdate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [endate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))
    const { fetchRenewalTotals, rtotals2, setRTotalNull2 } = useFieldDashboardStore()
    const { color } = useColorStore()

    const [fetcher, setFetcher] = useState({
        start: startdate, end: endate
    })
    useEffect(() => {
        if (fetcher.start.length > 0 && fetcher.end.length > 0) {
            fetchRenewalTotals(fetcher.start, fetcher.end)
        } else {
            setRTotalNull2()
        }
    }, [fetcher])

    // -------------------------------------Search Work-------------------------------------

    const [searchInput, setSearchInput] = useState("")

    const search = () => {
        navigate(AdminPath.SEARCH + '/' + searchInput)
    }

    return (
        <>
            {/* ------------------------------------------------- */}
            {token.user_type === userType.admin || token.user_type===userType.floor_manager ?
                <h6 style={{ color: color.darkText, marginBottom: '15px' }}>Renewal Dashboard</h6>
                : <div className="myrow mb-4">
                    <div className="ico-search d-flex align_center" style={{ position: 'relative' }}>
                        <div>
                            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
                            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                        </div>
                        <div className='ms-2'>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block mb-0' during={650}>
                                <button onClick={search} className="primaryButton "><Icon icon="ion:search-outline" fontSize={18} /></button>
                            </Ripples>
                        </div>
                    </div>
                </div>
            }

            <div className="myrow">
                <div style={{ color: color.darkText, fontSize: 12, marginRight: '10px' }}>
                    Start Date <span className="text-danger">*</span> <br />
                    <input type='date' value={fetcher.start} onChange={(e) => setFetcher({ ...fetcher, start: e.target.value })} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '150px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />   
                </div>
                <div style={{ color: color.darkText, fontSize: 12, marginRight: '10px' }}>
                    End Date <span className="text-danger">*</span> <br />
                    <input type='date' value={fetcher.end} onChange={(e) => setFetcher({ ...fetcher, end: e.target.value })} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '150px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />   
                </div>
                
            </div>
            {/* ------------------------------------------------- */}
            <div className="myrow justify_space_between">

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="ic:round-phone-callback" fontSize={32} color={color.blue} /></div>
                        </div>
                        <Link to={`${AdminPath.RENEWAL}?itemsPerPage=10&currentPage=1&owner=&status=&startDate=&endDate=&startDate2=&endDate2=&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Callbacks</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Now</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{rtotals2.total_callbacks ? numFormatter(rtotals2.total_callbacks) : 0}</div>
                        </Link>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="flat-color-icons:callback" fontSize={32} color={color.blue} /></div>
                        </div>
                        <Link to={`${AdminPath.RENEWAL}?itemsPerPage=10&currentPage=1&owner=&status=&startDate=&endDate=&startDate2=${today}&endDate2=${today}&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Today Callbacks</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>For Today</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(rtotals2.today_callbacks)}</div>
                        </Link>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="flat-color-icons:call-transfer" fontSize={32} color={color.blue} /></div>
                        </div>
                        <Link to={`${AdminPath.RENEWAL}?itemsPerPage=10&currentPage=1&owner=&status=&startDate=&endDate=&startDate2=${tommorow}&endDate2=${tommorow}&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Tomorrow Callbacks</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Now</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(rtotals2.tomorrow_callbacks)}</div>
                        </Link>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="flat-color-icons:end-call" fontSize={32} color={color.blue} /></div>
                        </div>
                        <Link to={`${AdminPath.RENEWAL}?itemsPerPage=10&currentPage=1&owner=&status=&startDate=&endDate=&startDate2=1900-01-01&endDate2=${today}&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Pending Callbacks</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Today</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(rtotals2.pending_callbacks)}</div>
                        </Link>
                    </div>
                </div>

            </div>

            {/* ------------------------------------------------- */}
            <div className="myrow justify_space_between">

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="hugeicons:renewable-energy-01" fontSize={32} color={color.blue} /></div>
                        </div>
                        <Link to={`${AdminPath.RENEWAL}?itemsPerPage=10&currentPage=1&owner=&status=&startDate=${fetcher.start}&endDate=${fetcher.end}&startDate2=&endDate2=&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Renewal</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Amount</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(rtotals2.tra)}</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>No. of Cases</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{rtotals2.total_renewal}</div>
                        </Link>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="ic:sharp-currency-rupee" fontSize={32} color={color.green} /></div>
                        </div>
                        <Link to={`${AdminPath.RENEWAL}?itemsPerPage=10&currentPage=1&owner=&status=PDR&startDate=${fetcher.start}&endDate=${fetcher.end}&startDate2=&endDate2=&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Paid</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Amount</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(rtotals2.tpa)}</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>No. of Cases</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{rtotals2.paid}</div>
                        </Link>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="material-symbols:pending-actions-rounded" fontSize={32} color={color.blue2} /></div>
                        </div>
                        <Link to={`${AdminPath.RENEWAL}?itemsPerPage=10&currentPage=1&owner=&status=U&startDate=${fetcher.start}&endDate=${fetcher.end}&startDate2=&endDate2=&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Unpaid</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Amount</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(rtotals2.tua)}</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>No. of Cases</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{rtotals2.unpaid}</div>
                        </Link>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="oui:index-close" fontSize={32} color={color.red} /></div>
                        </div>
                        <Link to={`${AdminPath.RENEWAL}?itemsPerPage=10&currentPage=1&owner=&status=L&startDate=${fetcher.start}&endDate=${fetcher.end}&startDate2=&endDate2=&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Lost</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Amount</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(rtotals2.tla)}</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>No. of Cases</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{rtotals2.lost}</div>
                        </Link>
                    </div>
                </div>

            </div>
        </>
    )
}

export default RenewalDashboard