import { Icon } from '@iconify/react/dist/iconify.js'
import React, { useState } from 'react'
import { useColorStore } from '../../../Store/ColorStore'
import moment from 'moment'
import { userType } from '../../../Export'
import { usePrStore } from '../../../Store/PrStore/PrStore'
import { useToasts } from 'react-toast-notifications'

const PrList = (props) => {
    const { addToast } = useToasts();
    const { data, count, isLoading, setIsLoading, reload, setReload } = props
    const { color } = useColorStore()
    let token = JSON.parse(localStorage.getItem('user'))
    const { deletePr, updatePr } = usePrStore()
    const handleDelete = () => {
        setIsLoading(true)
        deletePr(data.id)
            .then(res => {
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
                document.getElementById("dltBtn" + data.id).click()
                setReload(!reload)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }

    const [payload, setPayload] = useState({
        system_issue_date: data?.system_issue_date || '',
        inward_date: data?.inward_date || '',
        quote: data?.quote || '',
        prev_policy_number: data?.prev_policy_number || '',
        transaction_type: data?.transaction_type || '',
        product_name: data?.product_name || '',
        insured_name: data?.insured_name || '',
        address: data?.address || '',
        policy_number: data?.policy_number || '',
        policy_start_date: data?.policy_start_date || '',
        policy_end_date: data?.policy_end_date || '',
        endorsement: data?.endorsement || '',
        endorsement_reason: data?.endorsement_reason || '',
        endorsement_type: data?.endorsement_type || '',
        policy_type: data?.policy_type || '',
        sum_assured: data?.sum_assured || '',
        net_premium: data?.net_premium || '',
        gross_premium: data?.gross_premium || '',
        payment_mode: data?.payment_mode || '',
        instrument_number: data?.instrument_number || '',
        bank_name: data?.bank_name || '',
        branch_name: data?.branch_name || '',
    })

    const handleUpdate = () => {
        setIsLoading(true)
        updatePr(payload, data.id)
            .then(res => {
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
                document.getElementById("updateBtn" + data.id).click()
                setReload(!reload)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }
    return (
        <>
            <tr>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{count}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.system_issue_date ? data.system_issue_date : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.inward_date ? data.inward_date : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.quote ? data.quote : ''}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.prev_policy_number ? data.prev_policy_number : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.transaction_type ? data.transaction_type : '-'}</td>
                {/* <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.product_code ? data.product_code : '-'}</td> */}
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.product_name ? data.product_name : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.insured_name ? data.insured_name : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}><button className="infoButton" data-bs-toggle="modal" data-bs-target={"#lotsizeImportModal" + data.id} >View</button></td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.policy_number ? data.policy_number : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.policy_start_date ? data.policy_start_date : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.policy_end_date ? data.policy_end_date : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.endorsement ? data.endorsement : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.endorsement_type ? data.endorsement_type : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.endorsement_reason ? data.endorsement_reason : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.policy_type ? data.policy_type : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.sum_assured ? data.sum_assured : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.net_premium ? data.net_premium : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.gross_premium ? data.gross_premium : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.payment_mode ? data.payment_mode : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.instrument_number ? data.instrument_number : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.bank_name ? data.bank_name : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.branch_name ? data.branch_name : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{moment(data.created_at).format('LLL')}</td>
                {token.user_type === userType.admin || token.user_type === userType.operation_manager ?
                    <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                        <ul class="list-inline mb-0" style={{ width: '80px' }}>
                            <li class="list-inline-item">
                                <span type="button" class="px-2" data-bs-toggle="modal" data-bs-target={"#updateModal" + data.id} style={{ color: color.blue }}><Icon icon="akar-icons:edit" fontSize={18} /></span>
                            </li>
                            <li class="list-inline-item">
                                <span type={'button'} class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
                            </li>
                        </ul>
                    </td>
                    : null}
            </tr>
            {/* ----------------------Address Modal---------------------------- */}

            <div class="modal fade" id={"lotsizeImportModal" + data.id} tabindex="-1" aria-labelledby={"lotsizeImportModalLabel" + data.id} aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id={"lotsizeImportModalLabel" + data.id}>Address</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder, color: color.darkText }}>
                            {data.address || ''}
                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id='mclosebtn' style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ------------------------------Delete modal----------------------------------- */}

            <div className="modal fade" id={"categoryDeleteModal" + data?.id} tabIndex="-1" aria-labelledby="leadTrashModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to Delete this PR Entry? You would'nt be able to retrieve this.</div>
                        </div>
                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id={"dltBtn" + data.id} style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleDelete}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Proceed"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------------------Update PR Modal------------------------ */}

            <div class="modal fade" id={"updateModal" + data.id} tabindex="-1" aria-labelledby="updateModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-xl">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="followUpModalLabel">Update PR</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>

                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            <div className="myrow justify_space_between">

                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>System Issue Date <span className="text-danger">*</span></label>
                                        <input type='date' value={payload.system_issue_date} onChange={(e) => setPayload({ ...payload, system_issue_date: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Inward Date <span className="text-danger">*</span></label>
                                        <input type='date' value={payload.inward_date} onChange={(e) => setPayload({ ...payload, inward_date: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Quote</label>
                                        <input type='text' value={payload.quote} onChange={(e) => setPayload({ ...payload, quote: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Previous Policy Number</label>
                                        <input type='text' value={payload.prev_policy_number} onChange={(e) => setPayload({ ...payload, prev_policy_number: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Transaction Type</label>
                                        <input type='text' value={payload.transaction_type} onChange={(e) => setPayload({ ...payload, transaction_type: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Product Name</label>
                                        <input type='text' value={payload.product_name} onChange={(e) => setPayload({ ...payload, product_name: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Insured Name</label>
                                        <input type='text' value={payload.insured_name} onChange={(e) => setPayload({ ...payload, insured_name: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Policy number</label>
                                        <input type='text' value={payload.policy_number} onChange={(e) => setPayload({ ...payload, policy_number: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Policy Start Date <span className="text-danger">*</span></label>
                                        <input type='date' value={payload.policy_start_date} onChange={(e) => setPayload({ ...payload, policy_start_date: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Policy End Date <span className="text-danger">*</span></label>
                                        <input type='date' value={payload.policy_end_date} onChange={(e) => setPayload({ ...payload, policy_end_date: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Endorsement Number</label>
                                        <input type='text' value={payload.endorsement} onChange={(e) => setPayload({ ...payload, endorsement: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Endorsement Type</label>
                                        <input type='text' value={payload.endorsement_type} onChange={(e) => setPayload({ ...payload, endorsement_type: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Endorsement Reason</label>
                                        <input type='text' value={payload.endorsement_reason} onChange={(e) => setPayload({ ...payload, endorsement_reason: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Policy Type</label>
                                        <input type='text' value={payload.policy_type} onChange={(e) => setPayload({ ...payload, policy_type: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Total Sum Assured <span className="text-danger">*</span></label>
                                        <input type='text' value={payload.sum_assured} onChange={(e) => setPayload({ ...payload, sum_assured: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Net Premium <span className="text-danger">*</span></label>
                                        <input type='text' value={payload.net_premium} onChange={(e) => setPayload({ ...payload, net_premium: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Gross Premium <span className="text-danger">*</span></label>
                                        <input type='text' value={payload.gross_premium} onChange={(e) => setPayload({ ...payload, gross_premium: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Mode of Payment <span className="text-danger">*</span></label>
                                        <input type='text' value={payload.payment_mode} onChange={(e) => setPayload({ ...payload, payment_mode: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Instrument Number <span className="text-danger">*</span></label>
                                        <input type='text' value={payload.instrument_number} onChange={(e) => setPayload({ ...payload, instrument_number: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Bank Name</label>
                                        <input type='text' value={payload.bank_name} onChange={(e) => setPayload({ ...payload, bank_name: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Branch Name</label>
                                        <input type='text' value={payload.branch_name} onChange={(e) => setPayload({ ...payload, branch_name: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>

                                <div style={{ width: '100%' }}>
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Address</label>
                                        <textarea value={payload.address} onChange={(e) => setPayload({ ...payload, address: e.target.value })} placeholder='Enter address...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' ></textarea>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id={"updateBtn" + data.id} style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleUpdate}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Update"}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PrList