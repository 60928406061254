import React, { useState, useEffect } from 'react'
import { useColorStore } from '../../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { Link } from 'react-router-dom'
import { AdminPath } from '../../AdminRoutes/AdminPath'
import { useLeadImportStore } from '../../../../Store/LeadStore/LeadImportStore'
import { useUserStore } from './../../../../Store/UserStore/UserStore';
import moment from 'moment'
import { useAssignedLeadStore } from '../../../../Store/LeadStore/AssignedLeadStore'
import { useLeadDetailStore } from '../../../../Store/LeadStore/LeadDetailStore'
import { useToasts } from 'react-toast-notifications'
import { leadFollowUpOptions } from '../../../../Export'

const AssignedLeadList = (props) => {
    const { addToast } = useToasts()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading, handleSingleChange, checked, setReload, reload, assignedLeadsList } = props
    const { addFollowUp } = useLeadDetailStore()
    const { users, fetchUser } = useUserStore()
    const [enq, setEnq] = useState()

    useEffect(() => {
        let d = assignedLeadsList.find((f) => {
            return f.user === data.id
        })
        if (d) { setEnq(d) }
    }, [assignedLeadsList])

    const findOwner = (id) => {
        let u = users.find((f) => {
            return f.id === id
        })
        if (u) {
            return u.name
        } else {
            return '-'
        }
    }

    const findFollowUpState = (e) => {
        if (e.follow_up_state === 'SS') {
            if (e.service_state === 'P') {
                return 'Paid Client'
            }
            else if (e.service_state === 'R') {
                return 'Renew Client'
            }
            else if (e.service_state === 'F') {
                return 'Free Trial'
            } else {
                return null
            }
        } else {
            const d = leadFollowUpOptions.find((f) => f.value === e.follow_up_state)
            if (d) {
                return d.label
            } else {
                return null
            }
        }
    }

    // ----------------------------------Follow up Work------------------------------

    const [followupPayload, setFollowupPayload] = useState({
        follow_up_state: '',
        last_follow_up: '',
        callback_date: null,
    })

    useEffect(() => {
        if (enq) {
            setFollowupPayload({
                ...followupPayload,
                follow_up_state: enq.follow_up_state,
                last_follow_up: '',
                callback_date: null,
                reminder_message: '',
            })
        }
    }, [enq])


    const [error, setError] = useState(false)


    const handleAddFollowUp = () => {
        if (followupPayload.last_follow_up.length > 0 && followupPayload.callback_date) {
            setIsLoading(true)
            setError(false)
            let d = document.getElementById('closeBtn' + data.id)
            d.click()
            addFollowUp(data.id, followupPayload)
                .then(res => {
                    setFollowupPayload({
                        ...followupPayload, follow_up_state: '',
                        last_follow_up: '',
                        callback_date: null,
                        reminder_message: '',
                    })
                    setReload(!reload)
                    setIsLoading(false)
                    addToast('Success...', { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })

        } else {
            setError(true)
        }
    }

    return (
        <>
            <tr style={{ color: color.darkText }}>
                <td scope="col" style={{ borderBottom: '1px solid ' + color.tableBorder, width: '40px', color: color.darkText }}>
                    <input checked={checked.includes(data.id)} onChange={(e) => handleSingleChange(e, data)} type="checkbox" className="form-check-input" style={{ backgroundColor: color.inputBackground, border: "1px solid " + color.borderColor }} />
                    {/* <label className="form-check-label" for="contacusercheck"></label> */}
                </td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText, fontSize: '11px' }} scope="row">
                    <b>{enq ? enq.lead_c : ''}</b> <br />
                    <small class="d-inline-flex px-1 fw-medium text-success-emphasis bg-success-subtle border border-success-subtle rounded-2" style={{ fontSize: '12px', marginTop: '5px' }}>MBID - {enq ? enq.id : ''}</small>
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
                    <Link to={enq ? AdminPath.ALLLEADS + '/' + data.id : ''} target='blank' style={{ color: color.darkText }}><Icon icon="solar:user-outline" fontSize={14} color={color.blue} /> {data.name} <br /> <span style={{ fontSize: '11px', color: color.lightText, display: 'flex', alignItems: 'center' }}><Icon icon="uiw:time-o" color={color.lightText} fontSize={11} /> <span style={{ marginLeft: '6px' }}>{moment(data.created_at).format('lll')}</span></span> </Link>
                </td>
                {/* <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}><Icon icon="fluent:call-28-regular" fontSize={14} color={color.blue} /> {data.contact}<br />{data.email ? <> <Icon icon="fluent:mail-24-regular" fontSize={14} color={color.blue} /> <span style={{ fontSize: '13px', color: color.lightText }}> {data.email} </span></> : ''}</td> */}

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>{enq ? <>
                    {findOwner(enq.assign_to)}
                    <span style={{ color: color.lightText, display: 'flex', alignItems: 'center', fontSize: '12px' }}><Icon icon="uiw:time-o" color={color.blue} fontSize={12} /> <span style={{ marginLeft: '6px' }}>{moment(enq.assign_time).format('lll')}</span></span>
                </> : '-'}</td>
                {props?.motor &&
                    <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
                        {enq?.due_date ? moment(enq?.due_date).format('ll') : "-"}
                    </td>
                }

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}> {enq ? enq.isLead ? <span class="badge bg-success ms-2" style={{ fontSize: '15px' }}>Lead</span> : findFollowUpState(enq) ?
                    <>
                        <small className='d-inline-flex mb-2 px-2 text-success-emphasis bg-success-subtle border border-success-subtle rounded-2'>{findFollowUpState(enq)}</small>
                        <span style={{ color: color.lightText, display: 'flex', alignItems: 'center', fontSize: '12px', paddingTop: '5px' }}><Icon icon="uiw:time-o" color={color.blue} fontSize={12} /> <span style={{ marginLeft: '6px' }}>{moment(enq ? enq.follow_up_time : '').format('lll')}</span></span>
                    </>
                    : '-' : '-'}
                </td>

                <td style={{ color: color.lightText, borderBottom: '1px solid ' + color.tableBorder, fontSize: '12px' }}>
                    {enq ? moment(enq.callback_date).format('LLL') : '-'}
                </td>
                <td style={{ color: color.lightText, borderBottom: '1px solid ' + color.tableBorder, fontSize: '12px', whiteSpace: 'pre-wrap', maxWidth: '180px' }}>
                    <small type='button' data-bs-toggle="modal" className='mb-1' data-bs-target={"#addServiceFollowUpModal" + data.id} style={{ color: color.blue, paddingBottom: '5px' }}>
                        <u>Add New...</u>
                    </small>
                    <br />
                    {enq ? enq.last_followup ? enq.last_followup : '-' : ''}
                </td>

            </tr>


            {/* ----------------------Follow Up Add Modal---------------------------- */}

            <div class="modal fade" id={"addServiceFollowUpModal" + data.id} tabindex="-1" aria-labelledby="addServiceFollowUpModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="followUpModalLabel">Follow Up</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>Please fill Required fields...!</div>}

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Status <span className="text-danger">*</span></label>
                                <select value={followupPayload.follow_up_state} disabled={enq ? enq.follow_up_state === 'SS' : false} onChange={(e) => setFollowupPayload({ ...followupPayload, follow_up_state: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">-----Select Status-----</option>
                                    {leadFollowUpOptions.map((d) => {
                                        return <option value={d.value}>{d.label}</option>
                                    })}
                                </select>
                            </div>

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Next Callback Date <span className="text-danger">*</span></label>
                                <input type='datetime-local' value={followupPayload.callback_date} onChange={(e) => setFollowupPayload({ ...followupPayload, callback_date: e.target.value })} placeholder='Enter...' rows={5} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Remark <span className="text-danger">*</span></label>
                                <textarea value={followupPayload.last_follow_up} onChange={(e) => setFollowupPayload({ ...followupPayload, last_follow_up: e.target.value })} placeholder='Enter...' rows={5} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'></textarea>
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id={'closeBtn' + data.id} style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleAddFollowUp}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Save"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssignedLeadList