import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useAssignedLeadStore = create((set) => ({
    assignedLeads: [], newLeads: [], myData: [], newLeadsList: [], followUpLeads: [], followUpLeadsList: [], followUpLeadsOther: null,motorLeads:[],motorLeadList:[],motorLeadsOther:null,
    assignedLeadsList: [], myDataList: [], assignedLeadsOther: null, newLeadsOther: null,myDataOther: null,
    fetchAssignedLeads: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        const response = await axios.get(url + 'enquiry/fetch-assigned-enquiry/' + supervisor + '/?' + payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchMotorLeads: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        const response = await axios.get(url + 'enquiry/fetch-motor-enquiry/' + supervisor + '/?' + payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchMyData: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        const response = await axios.get(url + 'enquiry/fetch-my-data/' + supervisor + '/?' + payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchNewLeads: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        const response = await axios.get(url + 'enquiry/fetch-new-enquiry/' + supervisor + '/?' + payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchFollowUpLeads: async (id, payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        const response = await axios.get(url + 'enquiry/fetch-followup-enquiry/' + supervisor + '/' + id + '/?' + payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    setAssignedLeads: async (data) => {
        let d = [], enq = []
        for (let i = 0; i < data.data.length; i++) {
            const e = data.data[i];
            d.push({ id: e.user.id, name: e.user.name, email: e.user.email, contact: e.user.contact, created_at: e.user.created_at })
            enq.push({ id: e.id, lead_c:e.lead_c, assign_time: e.assign_time, user: e.user.id, assign_to: e.assign_to.id, created_at: e.created_at, follow_up_state: e.follow_up_state, service_state: e.service_state, follow_up_time: e.follow_up_time, last_followup: e.last_followup, isLead: e.isLead, lead_created: e.lead_created,callback_date:e.callback_date })
        }
        set({ assignedLeads: d, assignedLeadsList: enq, assignedLeadsOther: { count: Number(data.count), currentPage: Number(data.currentPage) } })
    },
    setMotorLeads: async (data) => {
        let d = [], enq = []
        for (let i = 0; i < data.data.length; i++) {
            const e = data.data[i];
            d.push({ id: e.user.id, name: e.user.name, email: e.user.email, contact: e.user.contact, created_at: e.user.created_at })
            enq.push({ id: e.id, lead_c:e.lead_c, assign_time: e.assign_time, user: e.user.id, assign_to: e.assign_to.id, created_at: e.created_at, follow_up_state: e.follow_up_state, service_state: e.service_state, follow_up_time: e.follow_up_time, last_followup: e.last_followup, isLead: e.isLead, lead_created: e.lead_created,callback_date:e.callback_date,due_date:e.due_date })
        }
        set({ motorLeads: d, motorLeadList: enq, motorLeadsOther: { count: Number(data.count), currentPage: Number(data.currentPage) } })
    },
    setMyData: async (data) => {
        let d = [], enq = []
        for (let i = 0; i < data.data.length; i++) {
            const e = data.data[i];
            d.push({ id: e.user.id, name: e.user.name, email: e.user.email, contact: e.user.contact, created_at: e.user.created_at })
            enq.push({ id: e.id, lead_c:e.lead_c, assign_time: e.assign_time, user: e.user.id, assign_to: e.assign_to.id, created_at: e.created_at, follow_up_state: e.follow_up_state, service_state: e.service_state, follow_up_time: e.follow_up_time, last_followup: e.last_followup, isLead: e.isLead, lead_created: e.lead_created,callback_date:e.callback_date })
        }
        set({ myData: d, myDataList: enq, myDataOther: { count: Number(data.count), currentPage: Number(data.currentPage) } })
    },
    setNewLeads: async (data) => {
        let d = [], enq = []
        for (let i = 0; i < data.data.length; i++) {
            const e = data.data[i];
            d.push({ id: e.user.id, name: e.user.name, email: e.user.email, contact: e.user.contact, created_at: e.user.created_at })
            enq.push({ id: e.id, lead_c:e.lead_c, assign_time: e.assign_time, user: e.user.id, assign_to: e.assign_to.id, created_at: e.created_at, follow_up_state: e.follow_up_state, service_state: e.service_state, follow_up_time: e.follow_up_time, last_followup: e.last_followup, isLead: e.isLead, lead_created: e.lead_created ,callback_date:e.callback_date})
        }
        set({ newLeads: d, newLeadsList: enq, newLeadsOther: { count: Number(data.count), currentPage: Number(data.currentPage) } })
    },
    setFollowUpLeads: async (data) => {
        let d = [], enq = []
        for (let i = 0; i < data.data.length; i++) {
            const e = data.data[i];
            d.push({ id: e.user.id, name: e.user.name, email: e.user.email, contact: e.user.contact, created_at: e.user.created_at })
            enq.push({ id: e.id, lead_c:e.lead_c, assign_time: e.assign_time, user: e.user.id, assign_to: e.assign_to.id, created_at: e.created_at, follow_up_state: e.follow_up_state, service_state: e.service_state, follow_up_time: e.follow_up_time, last_followup: e.last_followup, isLead: e.isLead, lead_created: e.lead_created,callback_date:e.callback_date })
        }
        set({ followUpLeads: d, followUpLeadsList: enq, followUpLeadsOther: { count: Number(data.count), currentPage: Number(data.currentPage) } })
    },
    updateAssignedLeadsList: async (alLeadData, payload) => {
        let items = [...alLeadData];
        for (let i = 0; i < payload.length; i++) {
            const e = payload[i];
            var foundIndex = await items.findIndex(x => x.id === e.id);
            items[foundIndex] = e
        }
        set({ assignedLeadsList: items });
    },
    removeAssignedLeadsList: async (alLeadData, payload) => {
        let items = [...alLeadData];
        let d = []
        for (let i = 0; i < items.length; i++) {
            const el = items[i];
            if (payload.includes(el.id)) {

            } else {
                d.push(el)
            }
        }
        set({ assignedLeads: d });
    },

}));