import React, { useState, useEffect } from 'react'
import './Header.css'
import { useColorStore } from '../../../Store/ColorStore'
import { Icon } from '@iconify/react';
import Ripples from 'react-ripples'
import NotificationContainer from './NotificationContainer';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link, useLocation } from 'react-router-dom'
import { AdminPath } from '../AdminRoutes/AdminPath';
import Sidebar from '../Sidebar/Sidebar';
import Clock from 'react-live-clock';
import moment from 'moment';
import { useMarketUpdateStore } from './../../../Store/MarketUpdateStore/MarketUpdateStore';
import { useUserStore } from '../../../Store/UserStore/UserStore';
import MarketUpdateList from './MarketUpdateList';
import { useToasts } from 'react-toast-notifications';
import { useSocketStore } from '../../../Store/SocketStore/SocketStore';
import { mediaUrl, userType } from '../../../Export';
import { useNotificationStore } from '../../../Store/NotificationStore/NotificationStore';
import { Avatar, Badge } from '@mui/material';


const Header = (props) => {
    let token = JSON.parse(localStorage.getItem('user'))
    const { addToast } = useToasts()
    const { user } = props
    const location = useLocation();
    let l = location.pathname.split('/')[1]
    let l2 = location.pathname.split('/')[2]

    const { color, fetchColor } = useColorStore()
    const { marketUpdate, fetchMarketUpdate, createMarketUpdate } = useMarketUpdateStore()
    const { users, fetchUsers } = useUserStore()

    let theme = JSON.parse(localStorage.getItem('theme'))
    const handleTheme = () => {
        localStorage.setItem('theme', JSON.stringify('dark'))
        fetchColor()
    }
    const removeTheme = () => {
        localStorage.removeItem('theme')
        fetchColor()
    }
    const [showDropDownNotification, setShowDropDownNotification] = useState(false)
    document.onclick = function () {
        setShowDropDownNotification(false)
    }

    let headTitle = ''
    if (l === 'category') {
        headTitle = "Category"
    }
    else if (l === '') {
        headTitle = 'Dashboard'
    }
    else if (l === 'sub-category') {
        headTitle = 'Sub Category'
    }
    else if (l === 'product') {
        headTitle = 'Product'
    }
    else if (l === 'pdf-files') {
        headTitle = 'Files > Pdf Files'
    }
    else if (l === 'product-brochure') {
        headTitle = 'Files > Product Brochure'
    }
    else if (l === 'qr-scanner') {
        headTitle = 'Files > Qr Scanner'
    }
    else if (l === 'dynamic-email') {
        headTitle = 'Dynamic Email'
    }
    else if (l === 'ip-address') {
        headTitle = 'IP Address'
    }
    else if (l === 'users') {
        headTitle = 'Users'
    }
    else if (l === 'claims') {
        headTitle = 'Claims'
    }
    else if (l === 'profile') {
        headTitle = 'Profile'
    }
    else if (l === 'email') {
        headTitle = 'Email'
    }
    else if (l === 'data') {
        headTitle = 'Data'
    }
    else if (l === 'my-data') {
        headTitle = 'My Data'
    }
    else if (l === 'mis') {
        headTitle = 'Sourcing'
    }
    else if (l === 'pr') {
        headTitle = 'PR'
    }
    else if (l === 'renewal') {
        headTitle = 'Renewal Leads'
    }
    else if (l === 'renew-data') {
        headTitle = token.user_type === userType.service_support ? "Service Data" : 'Renew Data'
    }
    else if (l === 'tasks') {
        headTitle = 'Task Management'
    }
    else if (l === 'leads') {
        headTitle = 'Leads'
    }
    else if (l === 'field-leads') {
        headTitle = 'Field Leads'
    }
    else if (l === 'motor-leads') {
        headTitle = 'Motor Leads'
    }
    else if (l === 'source-leads') {
        headTitle = 'Source Leads'
    }
    else if (l === 'service-list') {
        headTitle = 'Service List'
    }
    else if (l === 'assigned-data') {
        headTitle = 'Assigned Data'
    }
    else if (l === 'new-data') {
        headTitle = 'New Data'
    }
    else if (l === 'search') {
        headTitle = 'Search Result'
    }
    else if (l === 'invoices') {
        headTitle = 'Invoices'
    }
    else if (l === 'user-permissions') {
        headTitle = 'User Permissions'
    }
    else if (l === 'settings') {
        headTitle = 'Settings'
    }
    else if (l === 'lotsize') {
        headTitle = 'Lotsize'
    }
    else if (l === 'research-product') {
        headTitle = 'Research Product'
    }
    else if (l === 'research-update') {
        headTitle = 'Research Update'
    }
    else if (l === 'send-recommendations') {
        headTitle = 'Research Recommendation'
    }
    else if (l === 'send-updates') {
        headTitle = 'Research Updates'
    }
    else if (l === 'support-resistance') {
        headTitle = 'Support & Resistance'
    }
    else if (l === 'compliance-message') {
        headTitle = 'Compliance Message'
    }
    else if (l === 'past-performance') {
        headTitle = 'Past Performance'
    }
    else if (l === 'web-enquiry') {
        headTitle = 'Web Enquiry'
    }
    else if (l === 'requested-demo') {
        headTitle = 'Requested Demo'
    }
    else if (l === 'payment-user') {
        headTitle = 'Payment User'
    }
    else if (l === 'complaint') {
        headTitle = 'Complaint'
    }
    else if (l === 'complaint-data') {
        headTitle = 'Complaint Data'
    }
    else if (l === 'blogs') {
        headTitle = 'Blogs'
    }
    else if (l === 'recommendation') {
        headTitle = 'Recommendations'
    }
    else if (l === 'follow-up-data') {
        headTitle = l2 === 'interested' ? 'Interested' : l2 === 'not-interested' ? 'Not Interested' : l2 === 'npc' ? 'NPC' : l2 === 'call-back' ? 'Call Back' : null
    }

    const { newMarketUpdate, newNotification, clearNewNotification } = useSocketStore()
    useEffect(() => {
        fetchMarketUpdate()
        if (users.length === 0) {
            fetchUsers()
        }
    }, [newMarketUpdate])

    const [mUpdate, setMUpdate] = useState('')

    const handleSubmit = () => {
        if (mUpdate.length > 0) {
            createMarketUpdate({ content: mUpdate })
                .then(res => {
                    setMUpdate('')
                    addToast('Success...', { appearance: 'success' });
                })
                .catch(err => {
                    addToast('Something went wrong...', { appearance: 'error' });
                })
        }
    }

    // ******************************* Notification Work ****************************************

    const { unread_count, unread_notification, fetchUnreadNotification, fetchUnreadNotificationCount } = useNotificationStore()

    useEffect(() => {
        fetchUnreadNotificationCount()
    }, [])

    useEffect(() => {
        if (newNotification) {
            // addToast(newNotification, { appearance: 'info', autoDismiss: false, });
            fetchUnreadNotificationCount()
            clearNewNotification()
        }
    }, [newNotification])
    let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
    const [showDropDownNotification2, setShowDropDownNotification2] = useState(false)
    const handleChangeSupervisor = (d) => {
        localStorage.setItem("supervisor", JSON.stringify(d))
        window.location.reload();
    }

    const handleLogout = () => {
        localStorage.removeItem('user')
        localStorage.removeItem('supervisor')
        window.location.reload();
    }

    return (<>
        <div className="header" style={{ backgroundColor: color.primaryBg }}>
            <div className="myrow justify_space_between align_center">
                <div className='d-flex align_center'>
                    <Ripples className='mobile' color={color.rippleColor} during={1000}>
                        <Icon icon="tabler:menu-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" style={{ color: color.darkText }} className='headerIcon' />
                    </Ripples>
                    <div className='page-title' style={{ color: color.darkText }}>{headTitle}</div>
                </div>

                <div className='myrow align_center' style={{ position: 'relative' }}>
                    <div className='pe-2 desktop'>
                        <span style={{ color: color.green, fontSize: '15px' }}>{moment().format('LL')}</span>
                    </div>
                    <div className="ico-search desktop ps-2" style={{ borderLeft: "0.5px solid " + color.lightText }}>
                        <Clock format={'h:mm:ss A'} style={{ color: color.green, fontSize: '15px' }} ticking={true} />
                    </div>

                    <div style={{ marginLeft: '8px' }}>
                        {!theme ?
                            theme !== 'dark' ?
                                <Icon icon="carbon:moon" onClick={handleTheme} style={{ color: color.darkText }} className='headerIcon' />
                                :
                                <Icon icon="carbon:light" onClick={removeTheme} style={{ color: color.darkText }} className='headerIcon' />
                            :
                            <Icon icon="carbon:light" onClick={removeTheme} style={{ color: color.darkText }} className='headerIcon' />
                        }
                    </div>

                    <div style={{ marginLeft: '8px', marginBottom: '-5px' }}>
                        <Ripples color={color.rippleColor} during={1000}>
                            <Icon onClick={() => setShowDropDownNotification(!showDropDownNotification)} icon="ion:notifications-outline" style={{ color: color.darkText }} className='headerIcon' />
                            {unread_count > 0 && <span className='bg-danger text-white' style={{ position: 'absolute', right: 0, marginTop: '7px', fontSize: '12px', padding: '2px 5px', borderRadius: '50%' }}>{unread_count}</span>}
                        </Ripples>
                    </div>
                    {showDropDownNotification &&
                        <div className="more_block" style={{ backgroundColor: color.secondaryBg, border: "0.5px solid " + color.tableBorder }} onClick={(e) => e.stopPropagation()}>
                            <div className="p-3" style={{ borderBottom: '0.5px solid ' + color.borderColor }}>
                                <div className="myrow justify_space_between mt-1" >
                                    <h6 style={{ color: color.darkText, fontSize: '14px' }}>Notifications ({unread_count})</h6>
                                    {/* <small className='small_link'>
                                        View all
                                    </small> */}
                                </div>
                            </div>
                            <div className="notificationBlock">
                                <Scrollbars autoHide={true}>
                                    <NotificationContainer />
                                </Scrollbars>
                            </div>
                        </div>
                    }
                    <div className="ms-1" style={{ cursor: 'pointer' }} onClick={() => setShowDropDownNotification2(!showDropDownNotification2)}>
                        <div className='m-auto' style={{width:'50px'}}><Avatar src={user ? mediaUrl + user.proimg : ''} sx={{ bgcolor: "#0C768A", color: "#fff", fontSize: 14, fontWeight: 700, width: '35px', height: '35px', border: "3px solid #0C768A",margin:'auto' }} /></div>
                        <div className='px-1'>
                            <div className="reportingTitle text-center" style={{ color: color.darkText,width:'50px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }}>
                                {user ? user.name : ''}
                            </div>
                        </div>
                    </div>
                    {showDropDownNotification2 &&
                        <div>
                            <div className="more_block" style={{ backgroundColor: color.secondaryBg, marginTop: "80px" }} onClick={(e) => e.stopPropagation()}>

                                <div>
                                    <Link to={AdminPath.PROFILE + '/' + token.id} onClick={() => setShowDropDownNotification2(false)} className="d-flex notification_wrapper align_center" >
                                        <Icon icon="iconamoon:profile-circle-fill" color={color.blue} fontSize={18} />
                                        <div style={{ marginLeft: '10px' }}>
                                            <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Profile</div>
                                        </div>
                                    </Link>
                                </div>

                                {token.user_type !== userType.admin && token.user_type !== userType.tso ?
                                    !supervisor ?
                                        <div style={{ marginTop: '-10px' }}>
                                            <Link onClick={() => { setShowDropDownNotification2(false); handleChangeSupervisor(true) }} className="d-flex notification_wrapper align_center" >
                                                <Icon icon="icon-park:switch" color={color.blue} fontSize={16} />
                                                <div style={{ marginLeft: '10px' }}>
                                                    <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Supervisor</div>
                                                </div>
                                            </Link>
                                        </div>

                                        :

                                        <div style={{ marginTop: '-10px' }}>
                                            <Link onClick={() => { setShowDropDownNotification2(false); handleChangeSupervisor(false) }} className="d-flex notification_wrapper align_center" >
                                                <Icon icon="icon-park:switch" color={color.blue} fontSize={16} />
                                                <div style={{ marginLeft: '10px' }}>
                                                    <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>{token.email}</div>
                                                </div>
                                            </Link>
                                        </div>

                                    : null}

                                {token.user_type === userType.admin && <>
                                    <div style={{ marginTop: '-10px' }}>
                                        <Link to={AdminPath.USERPERMISSION} onClick={() => setShowDropDownNotification2(false)} className="d-flex notification_wrapper align_center" >
                                            <Icon icon="ic:outline-privacy-tip" color={color.green} fontSize={18} />
                                            <div style={{ marginLeft: '10px' }}>
                                                <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>User Permissions</div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div style={{ marginTop: '-10px' }}>
                                        <Link to={AdminPath.SETTINGS} onClick={() => setShowDropDownNotification2(false)} className="d-flex notification_wrapper align_center" >
                                            <Icon icon="lucide:settings" color={color.blue2} fontSize={18} />
                                            <div style={{ marginLeft: '10px' }}>
                                                <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Settings</div>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                                }

                                <div style={{ marginTop: '-10px' }}>
                                    <div className="d-flex notification_wrapper align_center" onClick={handleLogout}>
                                        <Icon icon="heroicons-outline:logout" color={color.yellow} fontSize={18} />
                                        <div style={{ marginLeft: '10px' }}>
                                            <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Log Out</div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ marginTop: '-10px' }}>
                                    <div className="d-flex notification_wrapper align_center" onClick={() => setShowDropDownNotification2(false)}>
                                        <Icon icon="ph:x-duotone" color={color.red} fontSize={15} style={{ marginTop: '' }} />
                                        <div style={{ marginLeft: '10px' }}>
                                            <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Close</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </div>

            </div>

            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <Sidebar user={user} />
            </div>
        </div>
        {/* ------------------------------Filter modal----------------------------------- */}

        <div className="offcanvas offcanvas-end" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} tabIndex="-1" id="MarketUpdateCanvas" aria-labelledby="offcanvasRightLabel">
            <div className="modal-content">
                <div className="modal-header px-3 pt-3" style={{ borderBottomColor: color.borderColor }}>
                    <div className="modal_title" style={{ color: color.darkText }} id="offcanvasRightLabel">Market Updates</div>
                    <span type="button" id='canvaCloseId' className='modal_close' data-bs-dismiss="offcanvas" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                </div>
            </div>
            <hr style={{ borderTop: "0.5px solid " + color.tableBorder, opacity: '1' }} />
            <div className="offcanvas-body">
                <Scrollbars autoHide={true}>
                    {user.user_type === userType.admin || user.user_type === userType.researcher || user.user_type === userType.sub_admin ? <>
                        <textarea placeholder='Special feedback...' value={mUpdate} onChange={(e) => setMUpdate(e.target.value)} rows={4} style={{ color: color.darkText, backgroundColor: color.inputBackground, borderRadius: '0px', border: '1px solid ' + color.tableBorder }} className='modal_input resize_none'>
                        </textarea>
                        <div className="text-end mt-2">
                            <button className="primaryButton w-100" onClick={handleSubmit}>Send</button>
                        </div>
                    </> : null}
                    {marketUpdate.map((m) => {
                        return <MarketUpdateList data={m} key={m.id} users={users} />
                    })}
                </Scrollbars>
            </div>
        </div>

    </>)
}

export default Header