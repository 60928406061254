import React, { useState, useEffect } from 'react'
import './Sidebar.css'
import logo from '../../../Images/moneybugs.png'
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Scrollbars } from 'react-custom-scrollbars';
import { Icon } from '@iconify/react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Ripples from 'react-ripples'
import { useColorStore } from '../../../Store/ColorStore';
import { Link, useLocation } from 'react-router-dom';
import { AdminPath } from './../AdminRoutes/AdminPath';
import { useUserStore } from '../../../Store/UserStore/UserStore';
import { fileUrl, mediaUrl, userType } from '../../../Export';

const AdminSidebar = (props) => {
  const { user } = props
  let location = useLocation();
  const { color } = useColorStore()
  let token = JSON.parse(localStorage.getItem('user'))
  let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
  return (
    <div className="menuWrapper">
      <Scrollbars autoHide={true}
        renderThumbVertical={props => <div {...props} style={{ backgroundColor: "rgba(255,255,255,0.2)", borderRadius: '5px' }} />}
      >
        {/* ======================================================================Dashboard Block========================================================= */}
        <div>
          <div className="menu-title">Menu</div>

          <Ripples color={color.rippleColor} during={800}>
            <Link to={AdminPath.DASHBOARD} className={location.pathname === AdminPath.DASHBOARD ? "menu-item menu-active" : "menu-item"}>
              <div className=""><Icon icon="uim:layer-group" fontSize={17} />&nbsp; Dashboard</div>
            </Link>
          </Ripples>

          <div id="accordionExample">

            <Ripples color={color.rippleColor} during={800} data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <div className={location.pathname === AdminPath.EMAIL || location.pathname === AdminPath.INBOX || location.pathname === AdminPath.SENTMAIL || location.pathname === AdminPath.TRASH || location.pathname === AdminPath.STARRED ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                <div className="d-flex align_center"><Icon icon="fluent:mail-48-filled" fontSize={18} />&nbsp; Email</div>
                <div style={{ marginLeft: 'auto' }}><KeyboardArrowDownRoundedIcon fontSize='small' /></div>
              </div>
            </Ripples>

            <div id="collapseOne" className="accordion-collapse collapse" style={{ paddingLeft: '15px' }} data-bs-parent="#accordionExample">
              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.INBOX} className={location.pathname === AdminPath.INBOX ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                  <div className="d-flex align_center"><Icon icon="mingcute:inbox-fill" fontSize={18} />&nbsp; Inbox</div>
                </Link>
              </Ripples>

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.SENTMAIL} className={location.pathname === AdminPath.SENTMAIL ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                  <div className="d-flex align_center"><Icon icon="ic:round-mark-email-read" fontSize={18} />&nbsp; Sent Mail</div>
                </Link>
              </Ripples>

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.TRASH} className={location.pathname === AdminPath.TRASH ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                  <div className="d-flex align_center"><Icon icon="basil:trash-solid" fontSize={18} />&nbsp; Trash</div>
                </Link>
              </Ripples>

            </div>
          </div>
        </div>
        {/* ======================================================================Research Calculator Block========================================================= */}

        {/* ======================================================================Product Block========================================================= */}

        {/* {token.user_type === userType.admin &&
                <div>
                  <div className="menu-title">Service</div>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.CATEGORY} className={location.pathname === AdminPath.CATEGORY ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="iconamoon:category-duotone" fontSize={18} />&nbsp; Category</div>
                    </Link>
                  </Ripples>
                  {user.user_type === userType.admin ?
                    <Ripples color={color.rippleColor} during={800}>
                      <Link to={AdminPath.SUBCATEGORY} className={location.pathname === AdminPath.SUBCATEGORY ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                        <div className=""><Icon icon="ic:twotone-category" fontSize={17} />&nbsp; Sub-Category</div>
                      </Link>
                    </Ripples>
                    : null}

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.PRODUCT} className={location.pathname === AdminPath.PRODUCT ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="uim:apps" fontSize={17} />&nbsp; Product</div>
                    </Link>
                  </Ripples>

                </div>
              } */}
        {/* ======================================================================Space Block========================================================= */}

        {/* {user.user_type === userType.admin ?
                <div>
                  <div className="menu-title">Content</div>

                  <div id="accordionExample">

                    <Ripples color={color.rippleColor} during={800} data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                      <div className={location.pathname === AdminPath.PDFFILES || location.pathname === AdminPath.PRODUCTBROCHURE || location.pathname === AdminPath.QRSCANNER ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                        <div className=""><Icon icon="mdi:files" fontSize={17} />&nbsp; Files</div>
                        <div style={{ marginLeft: 'auto' }}><KeyboardArrowDownRoundedIcon fontSize='small' /></div>
                      </div>
                    </Ripples>

                    <div id="collapseOne2" className="accordion-collapse collapse" style={{ paddingLeft: '15px' }} data-bs-parent="#accordionExample">
                      <Ripples color={color.rippleColor} during={800}>
                        <Link to={AdminPath.PDFFILES} className={location.pathname === AdminPath.PDFFILES ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                          <div className=""><Icon icon="material-symbols:picture-as-pdf-rounded" fontSize={18} />&nbsp; Pdf FIles</div>
                        </Link>
                      </Ripples>

                      <Ripples color={color.rippleColor} during={800}>
                        <Link to={AdminPath.PRODUCTBROCHURE} className={location.pathname === AdminPath.PRODUCTBROCHURE ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                          <div className=""><Icon icon="material-symbols:content-copy" fontSize={18} />&nbsp; Product Brochure</div>
                        </Link>
                      </Ripples>

                      <Ripples color={color.rippleColor} during={800}>
                        <Link to={AdminPath.QRSCANNER} className={location.pathname === AdminPath.QRSCANNER ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                          <div className=""><Icon icon="solar:qr-code-bold-duotone" fontSize={18} />&nbsp; Qr Scanner</div>
                        </Link>
                      </Ripples>

                    </div>
                  </div>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.DYNAMICEMAIL} className={location.pathname === AdminPath.DYNAMICEMAIL ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="material-symbols:stacked-email-rounded" fontSize={18} />&nbsp; Dynamic Email</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.IPADDRESS} className={location.pathname === AdminPath.IPADDRESS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="mdi:ip" fontSize={18} />&nbsp; WhiteListed IP</div>
                    </Link>
                  </Ripples>
                </div>
                : null} */}

        {/* ======================================================================Accounts Block========================================================= */}

        {user.user_type === userType.admin ?
          <div>
            <div className="menu-title">Account</div>

            <Ripples color={color.rippleColor} during={800}>
              <Link to={AdminPath.USERS} className={location.pathname === AdminPath.USERS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                <div className=""><Icon icon="solar:user-bold-duotone" fontSize={18} />&nbsp; Manage User</div>
              </Link>
            </Ripples>

          </div>
          : null}

        {/* ======================================================================Leads Block========================================================= */}

        <div>
          <div className="menu-title">Data</div>
          {user.user_type === userType.admin ?
            <Ripples color={color.rippleColor} during={800}>
              <Link to={AdminPath.ALLLEADS} className={location.pathname === AdminPath.ALLLEADS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                <div className=""><Icon icon="uim:layer-group" fontSize={17} />&nbsp; All Data</div>
              </Link>
            </Ripples>
            : null}

          <div id="accordionExample">

            <Ripples color={color.rippleColor} during={800} data-bs-toggle="collapse" data-bs-target="#collapseOne33" aria-expanded="true" aria-controls="collapseOne2">
              <div className={location.pathname === AdminPath.ASSIGNEDLEADS || location.pathname === AdminPath.NEWLEADS || location.pathname === AdminPath.FOLLOWUPLEADS + "/interested" || location.pathname === AdminPath.FOLLOWUPLEADS + "/ppt" || location.pathname === AdminPath.FOLLOWUPLEADS + "/call-back" ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                <div className=""><Icon icon="icon-park-twotone:data-two" fontSize={17} />&nbsp; Data</div>
                <div style={{ marginLeft: 'auto' }}><KeyboardArrowDownRoundedIcon fontSize='small' /></div>
              </div>
            </Ripples>

            <div id="collapseOne33" className="accordion-collapse collapse" style={{ paddingLeft: '15px' }} data-bs-parent="#accordionExample">

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.ASSIGNEDLEADS} className={location.pathname === AdminPath.ASSIGNEDLEADS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                  <div className=""><Icon icon="fluent:layer-diagonal-person-20-filled" fontSize={17} />&nbsp; Assigned Data</div>
                </Link>
              </Ripples>

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.NEWLEADS} className={location.pathname === AdminPath.NEWLEADS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                  <div className=""><Icon icon="ic:outline-accessibility-new" fontSize={17} />&nbsp; New Data</div>
                </Link>
              </Ripples>

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.FOLLOWUPLEADS + "/call-back"} className={location.pathname === AdminPath.FOLLOWUPLEADS + "/call-back" ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                  <div className=""><Icon icon="solar:incoming-call-rounded-bold-duotone" fontSize={17} />&nbsp; Call Back Data</div>
                </Link>
              </Ripples>

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.FOLLOWUPLEADS + "/interested"} className={location.pathname === AdminPath.FOLLOWUPLEADS + "/interested" ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                  <div className=""><Icon icon="mdi:alpha-i-box" fontSize={17} />&nbsp; Interested Data</div>
                </Link>
              </Ripples>

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.FOLLOWUPLEADS + "/ppt"} className={location.pathname === AdminPath.FOLLOWUPLEADS + "/ppt" ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                  <div className=""><Icon icon="mdi:alpha-p-box" fontSize={17} />&nbsp; PPT Data</div>
                </Link>
              </Ripples>

            </div>
          </div>

          <Ripples color={color.rippleColor} during={800}>
            <Link to={AdminPath.LEADS} className={location.pathname === AdminPath.LEADS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
              <div className=""><Icon icon="tabler:square-letter-l" fontSize={17} />&nbsp; Leads</div>
            </Link>
          </Ripples>

          < Ripples color={color.rippleColor} during={800}>
            <Link to={AdminPath.FIELDLEADS} className={location.pathname === AdminPath.FIELDLEADS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
              <div className=""><Icon icon="tabler:square-letter-f" fontSize={17} />&nbsp; Field Leads</div>
            </Link>
          </Ripples>

          < Ripples color={color.rippleColor} during={800}>
            <Link to={AdminPath.MIS} className={location.pathname === AdminPath.MIS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
              <div className=""><Icon icon="material-symbols:mist" fontSize={17} />&nbsp; Sourcing</div>
            </Link>
          </Ripples>

          <Ripples color={color.rippleColor} during={800}>
            <Link to={AdminPath.TASKS} className={location.pathname === AdminPath.TASKS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
              <div className=""><Icon icon="ic:twotone-task" fontSize={17} />&nbsp; Tasks</div>
            </Link>
          </Ripples>

        </div>


        {/* ======================================================================Operations Block========================================================= */}

        {user.user_type === userType.admin || user.user_type === userType.operation_manager ?
          user.user_type === userType.admin || supervisor ?
            <div>
              <div className="menu-title">Operations</div>

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.SOURCELEADS} className={location.pathname === AdminPath.SOURCELEADS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                  <div className=""><Icon icon="solar:user-bold-duotone" fontSize={18} />&nbsp; Source Leads</div>
                </Link>
              </Ripples>

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.MIS} className={location.pathname === AdminPath.MIS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                  <div className=""><Icon icon="material-symbols:mist" fontSize={18} />&nbsp; Sourcing</div>
                </Link>
              </Ripples>

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.PR} className={location.pathname === AdminPath.PR ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                  <div className=""><Icon icon="ri:git-pr-draft-line" fontSize={18} />&nbsp; PR</div>
                </Link>
              </Ripples>

            </div>
            : null
          : null}

        {/* ======================================================================Service / Renew Block========================================================= */}

        {user.user_type === userType.admin || user.user_type === userType.renewal_coordinator || user.user_type === userType.service_support ?
          user.user_type === userType.admin || supervisor ?
            <div>
              <div className="menu-title">Backend</div>

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.RENEWDATA} className={location.pathname === AdminPath.RENEWDATA ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                  <div className=""><Icon icon="material-symbols:autorenew-rounded" fontSize={18} />&nbsp; {token.user_type === userType.service_support ? "Service" : "Renew"} Data</div>
                </Link>
              </Ripples>

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.RENEWAL} className={location.pathname === AdminPath.RENEWAL ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                  <div className=""><Icon icon="hugeicons:renewable-energy" fontSize={18} />&nbsp; Renewal Leads</div>
                </Link>
              </Ripples>

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.MOTORLEADS} className={location.pathname === AdminPath.MOTORLEADS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                  <div className=""><Icon icon="tabler:circuit-motor" fontSize={17} />&nbsp; Motor Leads</div>
                </Link>
              </Ripples>

              <Ripples color={color.rippleColor} during={800}>
                <Link to={AdminPath.CLAIMS} className={location.pathname === AdminPath.CLAIMS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                  <div className=""><Icon icon="mingcute:shield-fill" fontSize={18} />&nbsp;Claims</div>
                </Link>
              </Ripples>


            </div>
            : null
          : null}

      </Scrollbars>
    </div >
  )
}

export default AdminSidebar